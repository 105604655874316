import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { customize, share } from "../assets";
import Gallery from "../components/holiday_package/gallery/Gallery";
import BookingCard from "../components/holiday_package/booking_card/BookingCard";
import Schedule from "../components/holiday_package/package_components/Schedule";
import { useMediaQuery } from "react-responsive";
import MobileNavbar from "../components/mobile-navbar/MobileNavbar";
import Footer from "../components/home/footer/Footer";
import About from "../components/home/about/About";
import { TiStarFullOutline } from "react-icons/ti";
import PackageTermsAndConditions from "../components/holiday_package/package_components/PackageTermsAndConditions";
import AboutSeller from "../components/holiday_package/package_components/AboutSeller";
import Reviews from "../components/holiday_package/package_components/Reviews";
import TripCustomization from "../components/holiday_package/sticky_trip_customization/StickyTripCustomization";
import Overview from "../components/holiday_package/package_components/Overview";

const PLACEAA_SINGLE_HOLIDAY_PACKAGE_DATA: any =
  "PLACEAA_SINGLE_HOLIDAY_PACKAGE_DATA";

const SinglePackage = () => {
  const location = useLocation();
  const isAboveMediumScreen = useMediaQuery({ minWidth: 768 });
  const rating = 3;

  const SECTION = [
    "Overview",
    "Itenary",
    "Terms & Conditions",
    "About Seller",
    "Reviews",
  ];

  const overviewRef = useRef<HTMLDivElement>(null);
  const scheduleRef = useRef<HTMLDivElement>(null);
  const termsRef = useRef<HTMLDivElement>(null);
  const sellerRef = useRef<HTMLDivElement>(null);
  const reviewsRef = useRef<HTMLDivElement>(null);
  const [selectedSection, setSelectedSection] = useState<string>("Overview");
  const [underlineWidth, setUnderlineWidth] = useState<number>(0);
  const [underlinePosition, setUnderlinePosition] = useState<number>(0);
  const sectionRefs = useRef<(HTMLParagraphElement | null)[]>([]);

  const [pageData, setPageData] = useState<any>();

  useEffect(() => {
    if (location?.state?.p) {
      setPageData(location?.state?.p);
      sessionStorage.setItem(
        PLACEAA_SINGLE_HOLIDAY_PACKAGE_DATA,
        JSON.stringify(location?.state?.p)
      );
    } else {
      const sessionData: any = sessionStorage?.getItem(
        PLACEAA_SINGLE_HOLIDAY_PACKAGE_DATA || ""
      );
      setPageData(JSON?.parse(sessionData));
    }
  }, [location?.state]);

  useEffect(() => {
    const currentSectionRef =
      sectionRefs.current[SECTION.indexOf(selectedSection)];
    if (currentSectionRef) {
      setUnderlineWidth(currentSectionRef.offsetWidth);
      setUnderlinePosition(currentSectionRef.offsetLeft);
    }
  }, [selectedSection]);

  const scrollToSection = (section: string) => {
    let targetRef: React.RefObject<HTMLDivElement> | null = null;

    switch (section) {
      case "Overview":
        targetRef = overviewRef;
        break;
      case "Itenary":
        targetRef = scheduleRef;
        break;
      case "Terms & Conditions":
        targetRef = termsRef;
        break;
      case "About Seller":
        targetRef = sellerRef;
        break;
      case "Reviews":
        targetRef = reviewsRef;
        break;
      default:
        break;
    }

    if (targetRef && targetRef.current) {
      const offset = 150;
      const targetPosition =
        targetRef.current.getBoundingClientRect().top + window.scrollY - offset;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    }

    setSelectedSection(section);
    const currentSectionRef = sectionRefs.current[SECTION.indexOf(section)];
    if (currentSectionRef) {
      setUnderlineWidth(currentSectionRef.offsetWidth);
      setUnderlinePosition(currentSectionRef.offsetLeft);
    }
  };

  return (
    <div>
      {!isAboveMediumScreen && <MobileNavbar />}
      <div className="h-auto">
        <div className="hidden md:flex lg:px-10 bg-white z-[4841] sticky top-0 lg:py-[23px] justify-between items-center">
          <div>
            <div className="flex items-center lg:gap-[11px] xl:gap-[15px]">
              <p className="lg:text-[22px] xl:text-[26px] 2xl:text-[30px] font-[600]">
                {pageData?.place}
              </p>
              <div className="flex items-center bg-black text-white py-[1.5px] xl:py-[2px] lg:px-[6px] xl:px-[8px] tracking-[0.7px] text-[12px] xl:text-[14px] 2xl:text-[16px] font-[600] rounded-[10px]">
                <p>{pageData?.nights}N</p>
                <p>/</p>
                <p>{pageData?.days}D</p>
              </div>
              <div className="flex items-center lg:gap-[2px] xl:gap-[4px] 2xl:gap-[6px] lg:px-[5px] xl:px-[6px] 2xl:px-[7px] py-[0.5px] xl:py-[1.6px] rounded-[10px] 2xl:scale-110 bg-black">
                <img
                  className="h-[7px] w-[10px] 2xl:scale-110"
                  src={customize}
                  alt=""
                />
                <p className="lg:text-[12px] xl:text-[14px]  text-white font-[600]">
                  CUSTOMIZABLE
                </p>
              </div>
            </div>
            <p className="text-[13px] xl:text-[14px] font-inter xl:-mt-1 font-[500] tracking-[0.6px]">
              Seller :{" "}
              <div className="inline-flex gap-2 items-center">
                {" "}
                <span className="text-[#AB0101]">{pageData?.offeredBy}</span>
                <div className="inline-flex">
                  {Array.from({ length: 5 }, (_, index) => (
                    <TiStarFullOutline
                      key={index}
                      size={20}
                      fill={index < rating ? "#AB0101" : "#d3d3d3"}
                    />
                  ))}
                </div>
              </div>
            </p>
          </div>
        </div>
        <div className="flex flex-col md:hidden px-5 pt-10 pb-5">
          <p className="text-xl font-semibold">
            Delightful Shimla & Manali Vacay
          </p>
          <div className="flex gap-2 font-medium">
            Seller : <span className="text-[#AB0101]">AdventureTravel</span>{" "}
          </div>
          <div className="w-full flex justify-between">
            <div className="flex gap-2 pt-1">
              <div className="flex items-center bg-black text-white py-1 px-3 tracking-[0.7px] text-sm  font-[600] rounded-full">
                <p>{pageData?.nights}N</p>
                <p>/</p>
                <p>{pageData?.days}D</p>
              </div>
              <div className="flex items-center gap-1  py-1 rounded-full px-2 bg-black">
                <img
                  className="h-[7px] w-[10px] 2xl:scale-110"
                  src={customize}
                  alt=""
                />
                <p className="text-sm text-white font-[600]">CUSTOMIZABLE</p>
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <img src={share} alt="" className="h-[15px] xl:h-[18px]" />
              <p className="text-xl font-semibold cursor-pointer">Share</p>
            </div>
          </div>
        </div>
        <div className="flex gap-10 md:px-10 px-5">
          <div className="flex flex-col gap-6 md:w-[65vw] w-full">
            <div className="w-full">
              <Gallery />
            </div>
            <div className="flex flex-col gap-1 sticky md:top-[105px] top-0 w-full bg-white z-[5000]">
              <div className="w-full flex justify-between items-center ">
                <div className="inline-flex md:gap-10 gap-5">
                  {SECTION.map((section, index) => (
                    <p
                      key={section}
                      ref={(el) => (sectionRefs.current[index] = el)}
                      className={`md:text-xl text-sm font-semibold cursor-pointer ${
                        selectedSection === section ? "text-[#AB0101]" : ""
                      }`}
                      onClick={() => scrollToSection(section)}
                    >
                      {section}
                    </p>
                  ))}
                </div>
                <div className="md:flex gap-2 items-center hidden ">
                  <img src={share} alt="" className="h-[15px] xl:h-[18px]" />
                  <p className="text-xl font-semibold cursor-pointer">Share</p>
                </div>
              </div>
              <div
                className="h-1 bg-[#AB0101] rounded-full"
                style={{
                  width: underlineWidth,
                  transform: `translateX(${underlinePosition}px)`,
                  transition: "width 0.3s ease, transform 0.3s ease",
                }}
              />
              <div className="w-full h-[0.5px] mt-2 bg-[#5E5E5E]" />
            </div>
            <div ref={overviewRef}>
              <Overview />
            </div>
            <div ref={scheduleRef}>
              <Schedule />
            </div>
            <div ref={termsRef}>
              <PackageTermsAndConditions />
            </div>
            <div ref={sellerRef}>
              <AboutSeller />
            </div>
            <div ref={reviewsRef}>
              <Reviews />
            </div>
          </div>
          <div className="w-[30vw]">
            {isAboveMediumScreen && (
              <div className="bg-white flex flex-col gap-5  z-[999999] sticky top-7 h-fit">
                <BookingCard
                  prices={pageData?.prices}
                  dates={{ from: "1/Mar", to: "7/Mar" }}
                  itemId={pageData?.id}
                />
                <TripCustomization />
              </div>
            )}
          </div>
        </div>
        {!isAboveMediumScreen && (
          <div className="fixed bottom-0 left-0 right-0 bg-black text-white p-4 flex justify-between items-center z-50 rounded-tr-2xl rounded-tl-2xl">
            <div className="flex flex-col">
              <p className="text-sm">Grand Total - 2 Adults</p>
              <h2 className="text-2xl font-bold">₹22316</h2>
              <p className="text-xs">(Inclusive of taxes)</p>
            </div>
            <button className="bg-[#AB0101] text-white px-6 py-2 rounded font-bold">
              Book Now
            </button>
          </div>
        )}
        {!isAboveMediumScreen && (
          <div className="mt-[40px] px-5 py-6 mx-5 md:px-0 border-[1px] shadow-lg rounded-xl">
            <p className="text-[16px] xl:text-[17px] font-[600]">
              Coupons & Offers
            </p>
            <div className="flex items-center p-[14px] xl:p-[16px] justify-between font-inter mt-[12px] xl:mt-[14px] border border-[#d9d9d9] rounded-[10px]">
              <p className="text-[12px] xl:text-[14px] font-[400]">
                Have a coupon code ?
              </p>
              <button className="text-[14px] xl:text-[15px] font-[600] text-[#3974CD]">
                Apply
              </button>
            </div>
          </div>
        )}
        <div className="mb-[60px]" />
        {!isAboveMediumScreen && (
          <>
            <About />
            <Footer />
          </>
        )}
      </div>
    </div>
  );
};

export default SinglePackage;
