import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { login_background } from "../assets";
import { verifyOTP, sendOTP, VerifyOTPResponse } from "../api/auth";
import { useAuth } from "../contexts/AuthContext";

const Otp: React.FC = () => {
  const [otp, setOtp] = useState("");
  const [input, setInput] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [resendMessage, setResendMessage] = useState("");
  const [countdown, setCountdown] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsLoggedIn } = useAuth();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const inputParam = params.get("input");
    if (inputParam) {
      setInput(inputParam);
    } else {
      navigate("/login");
    }
  }, [location, navigate]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const handleClose = () => {
    navigate("/");
  };

  const isEmail = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const isPhoneNumber = (value: string) => {
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    return phoneRegex.test(value);
  };

  const createPayload = () => {
    if (isEmail(input)) {
      return { email: input };
    } else if (isPhoneNumber(input)) {
      return { phone: input };
    } else {
      throw new Error(
        "Invalid input. Please enter a valid email or phone number."
      );
    }
  };

  const handleVerifyOTP = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      const payload = { ...createPayload(), otp };
      const response: VerifyOTPResponse = await verifyOTP(payload);
      console.log("OTP verified successfully:", response);

      localStorage.setItem("authToken", `Bearer ${response.token}`);
      setIsLoggedIn(true);

      if (response.profileVerified) {
        navigate("/");
      } else {
        navigate("/profile");
      }
    } catch (error) {
      console.error("Failed to verify OTP:", error);
      setError("Invalid OTP. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOTP = async () => {
    if (countdown > 0) return;
    setError("");
    setIsLoading(true);
    setIsResending(true);
    setResendMessage("");
    try {
      const payload = createPayload();
      await sendOTP(payload);
      setResendMessage(
        "OTP resent successfully. Please check your email or phone."
      );
      setCountdown(30);
    } catch (error) {
      console.error("Failed to resend OTP:", error);
      setError("Failed to resend OTP. Please try again.");
    } finally {
      setIsLoading(false);
      setIsResending(false);
    }
  };

  return (
    <div className="relative h-screen bg-gray-100">
      <div
        className="absolute inset-0 bg-cover bg-center bg-[image:var(--bg-image)] "
        style={{
          ["--bg-image" as string]: `url(${login_background})`,
        }}
      ></div>

      <div className="relative h-full flex flex-col items-center justify-center p-6">
        <button
          className="absolute top-4 right-4 text-black text-3xl"
          onClick={handleClose}
        >
          &times;
        </button>

        <div className="w-full max-w-md bg-white bg-opacity-25 rounded-lg shadow-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">
            Hello <span className="text-[#AB0101]">Traveller's</span>{" "}
            <span className="text-yellow-400">👋</span>
          </h2>

          <form onSubmit={handleVerifyOTP}>
            <label className="block text-sm font-semibold text-gray-700 mb-2">
              OTP has been sent to {input}
            </label>
            <input
              type="text"
              className="w-full px-3 py-2 border-black rounded-md mb-2 outline-none border-[1px] placeholder:text-sm"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
            {error && <p className="text-red-500 text-sm mb-2">{error}</p>}
            {resendMessage && (
              <p className="text-white text-sm mb-2">{resendMessage}</p>
            )}
            <button
              type="button"
              className={`text-[#082485] font-semibold mb-2 cursor-pointer flex items-center ${
                countdown > 0 ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={handleResendOTP}
              disabled={isResending || countdown > 0}
            >
              {isResending ? (
                <TailSpin
                  visible={true}
                  height="16"
                  width="16"
                  color="#082485"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{ marginRight: "8px" }}
                  wrapperClass=""
                />
              ) : null}
              {countdown > 0 ? `Resend OTP (${countdown}s)` : "Resend OTP"}
            </button>
            <button
              className="w-full bg-[#AB0101] text-white py-2 rounded-full transition duration-300 flex items-center justify-center"
              disabled={isLoading}
            >
              {isLoading ? (
                <TailSpin
                  visible={true}
                  height="24"
                  width="24"
                  color="#ffffff"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                />
              ) : (
                "Verify"
              )}
            </button>
          </form>

          <div className="mt-6 space-y-2">
            {[1, 2].map((_, index) => (
              <div
                key={index}
                className="bg-[#F5F5F5] p-3 rounded-md flex items-center gap-3"
              >
                <div className="bg-black text-white text-[10px] font-bold rounded-full w-16 h-10 flex flex-col items-center justify-center">
                  <p>10%</p>
                  <p>OFF </p>
                </div>
                <div className="text-sm">
                  <p className="font-semibold">Welcome Offer</p>
                  <p className="text-xs">
                    10% instant discount on First Booking for new customers only
                  </p>
                </div>
              </div>
            ))}
          </div>
          <p className="mt-6 text-xs text-white text-center">
            By proceeding you agree with our{" "}
            <a href="#" className="text-[#AB0101] font-semibold">
              Privacy Policy
            </a>
            ,{" "}
            <a href="#" className="text-[#AB0101] font-semibold">
              T&Cs
            </a>{" "}
            and{" "}
            <a href="#" className="text-[#AB0101] font-semibold">
              User Agreement
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Otp;
