import { useMediaQuery } from "react-responsive";
import { mountains } from "../assets";
import Footer from "../components/home/footer/Footer";
import MobileNavbar from "../components/mobile-navbar/MobileNavbar";

const AboutUs = () => {
  const isAboveMediumScreen = useMediaQuery({ minWidth: 768 });

  return (
    <>
      {!isAboveMediumScreen && <MobileNavbar />}

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="mb-12">
          <div className="flex md:flex-row flex-col md:gap-16">
            <div className="md:w-[40vw]">
              <h1 className="text-6xl font-semibold mb-6 text-center md:text-start">
                ABOUT US
              </h1>
              <p className="md:text-lg mb-6">
                Welcome to PLACEAA, your ultimate travel tech solution! We are a
                forward-thinking startup dedicated to revolutionizing the travel
                industry through innovative technology and exceptional service.
              </p>
              <img
                src={mountains}
                alt="Scenic mountain view"
                className="w-full h-40 object-cover mb-6 rounded-2xl"
              />
            </div>
            <div className="flex flex-col gap-5 md:gap-0 md:w-[40vw]">
              <div className="flex-1">
                <h2 className="text-3xl font-semibold mb-4">Our Mission</h2>
                <p>
                  Our mission is to simplify travel planning and enhance the
                  online presence of tour operators by leveraging cutting-edge
                  artificial intelligence, e-commerce, and customizable
                  technology solutions. We aim to create seamless, personalized
                  travel experiences that cater to the unique preferences and
                  needs of every traveler.
                </p>
              </div>
              <div className="flex-1">
                <h2 className="text-3xl font-semibold mb-4">Our Vision</h2>
                <p>
                  We envision a world where travel planning is effortless, and
                  tour operators can efficiently manage and promote their
                  services. By integrating advanced technology with the travel
                  industry, we strive to make dream vacations a reality for
                  everyone, while empowering tour operators to reach their full
                  potential.
                </p>
              </div>
            </div>
          </div>

          <h2 className="text-2xl font-semibold mb-4 mt-10">About PLACEAA</h2>
          <p className="mb-6">
            Founded by Shubh Mangukiya, a final-year B.Tech Computer Science
            student and passionate traveler from Surat, Gujarat, PLACEAA was
            born from a simple travel blog. What started as a personal interest
            in travel and a way to earn some pocket money soon evolved into a
            full-fledged business idea. Recognizing the inefficiencies in travel
            planning and the challenges faced by tour operators, Shubh set out
            to create a comprehensive solution that addresses these issues
            head-on.
          </p>

          <h2 className="text-2xl font-semibold mb-4">Our Solution</h2>
          <p className="mb-4">
            PLACEAA offers a unique blend of AI-generated travel itineraries,
            customizable white-label websites, and an integrated e-commerce
            marketplace. Our platform provides:
          </p>
          <ul className="list-disc pl-6 mb-6">
            <li>
              Personalized Itineraries: AI-driven day-wise travel plans based on
              user preferences and budgets.
            </li>
            <li>
              White-Label Solutions: Customizable websites for tour operators to
              enhance their online presence.
            </li>
            <li>
              E-commerce Marketplace: A dual platform solution that increases
              visibility and revenue for tour operators.
            </li>
            <li>
              Business Analysis: AI-driven business analysis according to market
              trends.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mb-4">Why Choose PLACEAA?</h2>
          <p className="mb-6">
            At PLACEAA, we are committed to delivering exceptional value through
            our innovative technology and customer-centric approach. Our diverse
            revenue models, including advertising, commissions, and
            subscriptions, ensure sustainability and continued growth. We are
            dedicated to making travel planning seamless and enjoyable, while
            supporting the success of our partners in the travel industry.
          </p>
          <p className="font-semibold">
            Join us on this exciting journey and experience the future of travel
            planning with PLACEAA!
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
