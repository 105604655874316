import Slider from "react-slick";
import { travel_gallery } from "../../../assets";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";

const Gallery = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false, // Disable default dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current: number, next: number) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Custom Dots Component
  const CustomDots = () => {
    return (
      <div className="absolute bottom-3 left-1/2 transform -translate-x-1/2 flex justify-center mt-4">
        {Array.from({ length: 2 }).map((_, index) => (
          <div
            key={index}
            className={`w-8 h-1 mx-1 rounded-full ${
              currentSlide === index ? "bg-[#AB0101]" : "bg-white"
            }`}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="relative rounded-xl overflow-hidden">
      <Slider {...settings} className="outline-none">
        <div className="w-full h-[40vh] outline-none">
          <img
            src={travel_gallery}
            alt="travel photos"
            className="object-cover w-full h-full"
          />
        </div>
        <div className="w-full h-[40vh] outline-none">
          <img
            src={travel_gallery}
            alt="travel photos"
            className="object-cover w-full h-full"
          />
        </div>
      </Slider>
      <CustomDots />
    </div>
  );
};

export default Gallery;
