import { TiStarFullOutline } from "react-icons/ti";

const REVIEWS = [
  {
    name: "Shubh Mangukiya",
    review:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  },
  {
    name: "Shubh Mangukiya",
    review:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  },
  {
    name: "Shubh Mangukiya",
    review:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
  },
];

const Reviews = () => {
  const rating = 3;

  return (
    <div className="py-10 flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <div className="flex gap-2 items-center">
          <h1 className="md:text-2xl text-xl font-semibold">Reviews</h1>
          <div className="inline-flex">
            {Array.from({ length: 5 }, (_, index) => (
              <TiStarFullOutline
                key={index}
                size={20}
                fill={index < rating ? "#AB0101" : "#d3d3d3"}
              />
            ))}
          </div>
        </div>
        <button className="bg-white md:px-5 md:py-2 py-1 px-3 border-2 border-[#AB0101] rounded-full text-sm md:text-md text-[#AB0101]">
          Write a Review
        </button>
      </div>
      <div className="w-full h-[0.5px] bg-[#B9B9B980]" />
      <div className="flex flex-col gap-4">
        {REVIEWS.map((review) => (
          <div className=" flex gap-3 mt-4">
            <div className="md:h-12 md:w-14 h-8 w-24 rounded-full bg-[#AB0101] text-white md:text-lg text-sm font-bold flex justify-center items-center">
              SM
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col">
                <p className="md:text-lg font-semibold">{review.name}</p>
                <div className="inline-flex">
                  {Array.from({ length: 5 }, (_, index) => (
                    <TiStarFullOutline
                      key={index}
                      size={20}
                      fill={index < rating ? "#AB0101" : "#d3d3d3"}
                    />
                  ))}
                </div>
              </div>
              <p className="text-sm md:text-md">{review.review}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Reviews;
