import { useNavigate } from "react-router-dom";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { arrow, error } from "../assets";
import MiniFooter from "../components/mini_footer/MiniFooter";
import { useMediaQuery } from "react-responsive";
import MobileNavbar from "../components/mobile-navbar/MobileNavbar";
import Footer from "../components/home/footer/Footer";

const NotFound = () => {
  const navigate = useNavigate();
  const isAboveMediumScreen = useMediaQuery({ minWidth: 768 });

  return (
    <>
      {!isAboveMediumScreen && <MobileNavbar />}
      <div className="flex flex-col items-center gap-28 justify-center h-screen bg-[#050505]">
        <img src={error} alt="404" className="px-10 md:px-0" />
        <div className="w-full flex flex-col justify-center items-center">
          <p className="uppercase tracking-[6px] text-white font-semibold">
            BACK
          </p>
          <img
            src={arrow}
            alt="arrow"
            className="w-14 cursor-pointer"
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
