import {
  adventure,
  family,
  honeymoon,
  package_form_image,
  relegious,
  solo,
} from "../../../assets";
import { LiaExchangeAltSolid } from "react-icons/lia";

const Themes = [
  {
    name: "Honeymoon",
    image: honeymoon,
  },
  {
    name: "Adventure",
    image: adventure,
  },
  {
    name: "Solo",
    image: solo,
  },
  {
    name: "Religious",
    image: relegious,
  },
  {
    name: "Group/Family",
    image: family,
  },
];

const PersonalizedTravelPackageForm = () => {
  return (
    <div className="md:px-32 px-3 pb-12 md:pb-0">
      <div className="bg-white shadow-md rounded-3xl p-2 md:p-5 flex md:flex-row flex-col justify-between">
        <div
          className="rounded-xl h-[55vh] md:w-[36vw] text-white bg-cover bg-no-repeat p-10 text-4xl"
          style={{
            backgroundImage: `url(${package_form_image})`,
          }}
        >
          Get Your
          <br />
          <span className="font-bold">Personalized</span>
          <br />
          Travel
          <br />
          <span className="font-bold">Package</span>
        </div>
        <div className="flex flex-col gap-3 md:px-6 px-2 md:py-2 py-4 md:w-[50vw]">
          <p className="text-left font-medium text-lg">
            Share your details for a custom tour plan from our experts.
          </p>
          <div className="flex relative">
            <input
              type="text"
              className="border border-[#ADADAD] px-3 py-3 rounded-l-xl w-full placeholder:text-center outline-none"
              placeholder="From"
            />
            <div className="absolute left-1/2 -translate-x-5 md:translate-y-0 -translate-y-[0.8px] bg-white  py-[4px] my-[1px]">
              <div className=" w-10 h-10 rounded-full bg-black items-center flex justify-center">
                <LiaExchangeAltSolid fill="#ffffff" />
              </div>
            </div>
            <input
              type="text"
              className="border border-[#ADADAD] px-3 py-3 rounded-r-xl w-full placeholder:text-center outline-none"
              placeholder="To"
            />
          </div>
          <div className="flex md:flex-row flex-col gap-3 items-center">
            <select className="border border-[#ADADAD] px-3 py-3 rounded-xl w-full outline-none">
              <option value="select" className="text-[#ADADAD]">
                Select Duration
              </option>
              <option value="1 Day">1 Day</option>
            </select>
            <select className="border border-[#ADADAD] px-3 py-3 rounded-xl w-full outline-none">
              <option value="select" className="text-[#ADADAD]">
                Select Budget
              </option>
              <option value="10000-20000">10000-20000</option>
            </select>
          </div>
          <div className="flex flex-col gap-2 my-1">
            <p className="text-[#5E5E5E] font-medium">Theme for travel</p>

            <div className="flex gap-3 flex-wrap">
              {Themes.map((theme) => (
                <div
                  className="w-32 h-14 bg-red-600 rounded-2xl flex justify-center items-center font-semibold text-white bg-cover bg-no-repeat"
                  style={{
                    backgroundImage: `url(${theme.image})`,
                  }}
                >
                  {theme.name}
                </div>
              ))}
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-3 item-center">
            <input
              type="text"
              className="border border-[#ADADAD] px-3 py-3 rounded-xl w-full outline-none"
              placeholder="Full Name"
            />
            <input
              type="text"
              className="border border-[#ADADAD] px-3 py-3 rounded-xl w-full outline-none"
              placeholder="Mobile Number"
            />
          </div>
          <button className="w-full bg-[#AB0101] rounded-xl text-white py-3 mt-2">
            Submit we will contact you
          </button>
        </div>
      </div>
    </div>
  );
};

export default PersonalizedTravelPackageForm;
