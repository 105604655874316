const About = () => {
  return (
    <div className="lg:mx-[30px] xl:mx-[40px] mx-5 py-10 md:py-0">
      <p className="text-[#000] lg:text-[22px] xl:text-[25px] 2xl:text-[28px] font-[600]">
        Why PLACEAA ?
      </p>
      <p className="text-[#5e5e5e] lg:text-[12px] xl:text-[15px] 2xl:text-[18px] font-[400] mt-[12px]">
        PLACEAA represents a fusion of "place" and "AI," embodying our
        commitment to leveraging cutting-edge technology to enhance the travel
        experience. It's not just about visiting destinations; it's about
        crafting memorable journeys. With PLACEAA, travelers access personalized
        AI-driven planning, collaborative tour creation, and a rich image
        library for informed decisions. For businesses, it's a gateway to
        cost-effective bookings, detailed insights, and growth guidance. Our
        platform is a testament to seamless exploration, where innovation meets
        adventure, ensuring every traveler finds their perfect place. Choose
        PLACEAA for transformative travel experiences tailored to your unique
        journey.
      </p>
    </div>
  );
};

export default About;
