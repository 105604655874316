import { useMediaQuery } from "react-responsive";
import Footer from "../components/home/footer/Footer";
import MobileNavbar from "../components/mobile-navbar/MobileNavbar";

const privacyPolicyContent = [
  {
    effectiveDate: "11/July/2024",
    introduction:
      "Welcome to PLACEAA! We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, share, and protect your data when you use our services.",
    sections: [
      {
        title: "Information We Collect",
        content: [
          {
            subtitle: "Personal Information:",
            text: "When you register or use our services, we may collect personal information such as your name, email address, phone number, and payment details.",
          },
          {
            subtitle: "Travel Information:",
            text: "We collect details related to your travel preferences, itineraries, bookings, and feedback to provide a personalized experience.",
          },
          {
            subtitle: "Usage Data:",
            text: "Information about your interactions with our website and app, including IP address, browser type, device information, and pages visited.",
          },
          {
            subtitle: "Cookies and Tracking Technologies:",
            text: "We use cookies and similar technologies to enhance your experience, analyze site usage, and deliver personalized content.",
          },
        ],
      },
      {
        title: "How We Use Your Information",
        content: [
          {
            subtitle: "Service Delivery:",
            text: "To provide and manage our travel services, process bookings, and fulfill your travel requests.",
          },
          {
            subtitle: "Personalization:",
            text: "To tailor our services to your preferences and provide customized travel recommendations.",
          },
          {
            subtitle: "Communication:",
            text: "To send you updates, promotional offers, and other communications related to your bookings and interests.",
          },
          {
            subtitle: "Analytics:",
            text: "To analyze user behavior, improve our services, and enhance the user experience.",
          },
          {
            subtitle: "Legal Compliance:",
            text: "To comply with legal obligations and protect our rights and interests.",
          },
        ],
      },
      {
        title: "Information Sharing and Disclosure",
        content: [
          {
            subtitle: "Service Providers:",
            text: "We may share your information with trusted third-party service providers who assist us in delivering our services.",
          },
          {
            subtitle: "Business Partners:",
            text: "We may share information with our business partners for marketing and promotional purposes.",
          },
          {
            subtitle: "Legal Requirements:",
            text: "We may disclose your information if required by law or to protect our rights, property, or safety.",
          },
        ],
      },
      {
        title: "Data Security",
        content:
          "We implement robust security measures to protect your personal information from unauthorized access, alteration, and disclosure. However, no method of transmission over the internet or electronic storage is 100% secure.",
      },
      {
        title: "Your Rights and Choices",
        content: [
          {
            subtitle: "Access and Correction:",
            text: "You can access and update your personal information through your account settings.",
          },
          {
            subtitle: "Opt-Out:",
            text: "You can opt-out of receiving promotional communications by following the unsubscribe instructions in the emails.",
          },
          {
            subtitle: "Cookies:",
            text: "You can manage your cookie preferences through your browser settings.",
          },
        ],
      },
      {
        title: "Third-Party Links",
        content:
          "Our website may contain links to third-party sites. We are not responsible for the privacy practices or content of these sites.",
      },
      {
        title: "Children's Privacy",
        content:
          "Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children.",
      },
      {
        title: "Changes to This Privacy Policy",
        content:
          "We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our website and updating the effective date.",
      },
      {
        title: "Contact Us",
        content:
          "If you have any questions or concerns about this Privacy Policy, please contact us at:",
        contactInfo: [
          "PLACEAA",
          "Email: support@placeaa.com",
          "Phone: +91 90993 31142",
        ],
      },
    ],
    conclusion: "Thank you for trusting PLACEAA with your travel needs.",
  },
];

const PrivacyPolicy = () => {
  const content = privacyPolicyContent[0];
  const isAboveMediumScreen = useMediaQuery({ minWidth: 768 });

  return (
    <>
      {!isAboveMediumScreen && <MobileNavbar />}
      <div className="w-full flex justify-center px-5 md:px-10 py-8 items-center">
        <div className="md:w-[70vw]">
          <h1 className="text-3xl font-bold mb-4 text-center">
            Privacy Policy
          </h1>
          <p className="text-sm mb-4">
            Effective Date: {content.effectiveDate}
          </p>
          <p className="font-semibold text-xl mb-2">Introduction</p>
          <p className="mb-4">{content.introduction}</p>

          {content.sections.map((section, index) => (
            <section key={index} className="mb-6">
              <h2 className="text-xl font-semibold mb-2">
                {index + 1}. {section.title}
              </h2>
              {Array.isArray(section.content) ? (
                section.content.map((item, i) => (
                  <p key={i} className="mb-2">
                    <span className="font-semibold">{item.subtitle}</span>{" "}
                    {item.text}
                  </p>
                ))
              ) : (
                <p>{section.content}</p>
              )}
              {section.contactInfo && (
                <div>
                  {section.contactInfo.map((info, i) => (
                    <p key={i}>{info}</p>
                  ))}
                </div>
              )}
            </section>
          ))}

          <p className="text-sm italic">{content.conclusion}</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
