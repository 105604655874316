import { useMediaQuery } from "react-responsive";
import Footer from "../components/home/footer/Footer";
import MobileNavbar from "../components/mobile-navbar/MobileNavbar";

const userAgreementContent = [
  {
    effectiveDate: "11/July/2024",
    introduction:
      'This User Agreement (the "Agreement") governs your use of the PLACEAA website, app, and services (collectively, the "Services"). By accessing or using our Services, you agree to be bound by this Agreement. Please read it carefully.',
    sections: [
      {
        title: "Acceptance of Terms",
        content:
          "By using our Services, you agree to comply with and be legally bound by this Agreement and our Privacy Policy. If you do not agree to these terms, please do not use our Services.",
      },
      {
        title: "Eligibility",
        content:
          "You must be at least 18 years old to use our Services. By using our Services, you represent and warrant that you meet this age requirement.",
      },
      {
        title: "Use of Services",
        content: [
          {
            subtitle: "License:",
            text: "PLACEAA grants you a limited, non-exclusive, non-transferable, and revocable license to use our Services for personal, non-commercial purposes, subject to this Agreement.",
          },
          {
            subtitle: "Account Registration:",
            text: "To access certain features, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.",
          },
          {
            subtitle: "Account Security:",
            text: "You are responsible for maintaining the confidentiality of your account login information and for all activities that occur under your account. You agree to notify PLACEAA immediately of any unauthorized use of your account.",
          },
        ],
      },
      {
        title: "Booking and Payments",
        content: [
          {
            subtitle: "Bookings:",
            text: "When you make a booking through PLACEAA, you agree to provide accurate and complete information. All bookings are subject to availability and confirmation.",
          },
          {
            subtitle: "Payments:",
            text: "You agree to pay all charges associated with your booking, including taxes and fees. Payment must be made using the methods specified on our platform.",
          },
          {
            subtitle: "Cancellation and Refunds:",
            text: "Cancellation and refund policies vary by service provider. Please review the specific terms and conditions of your booking. PLACEAA may charge a processing fee for cancellations.",
          },
        ],
      },
      {
        title: "User Conduct",
        content:
          "You agree not to:\n- Use our Services for any unlawful purpose or in violation of any applicable laws.\n- Interfere with or disrupt the integrity or performance of our Services.\n- Attempt to gain unauthorized access to any part of our Services.\n- Post, upload, or distribute any content that is unlawful, defamatory, or infringing on others' rights.",
      },
      {
        title: "Intellectual Property",
        content:
          "All content and materials available on PLACEAA, including but not limited to text, graphics, logos, and software, are the property of PLACEAA or its licensors and are protected by copyright, trademark, and other intellectual property laws. You agree not to reproduce, distribute, or create derivative works from any content without our express written permission.",
      },
      {
        title: "Third-Party Links",
        content:
          "Our Services may contain links to third-party websites or services. We are not responsible for the content or practices of these third parties. Your use of third-party websites or services is at your own risk.",
      },
      {
        title: "Limitation of Liability",
        content:
          "To the maximum extent permitted by law, PLACEAA shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of our Services. Our total liability to you for any claims arising from your use of the Services is limited to the amount you paid, if any, for accessing our Services.",
      },
      {
        title: "Indemnification",
        content:
          "You agree to indemnify, defend, and hold harmless PLACEAA, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising out of or in any way connected with your use of our Services or your violation of this Agreement.",
      },
      {
        title: "Termination",
        content:
          "PLACEAA may terminate or suspend your access to our Services at any time, without notice, for conduct that PLACEAA believes violates this Agreement or is harmful to other users or the interests of PLACEAA.",
      },
      {
        title: "Governing Law",
        content:
          "This Agreement shall be governed by and construed in accordance with the laws of India, without regard to its conflict of laws principles.",
      },
      {
        title: "Dispute Resolution",
        content:
          "Any disputes arising out of or relating to this Agreement or our Services shall be resolved through binding arbitration in accordance with the Indian Arbitration and Conciliation Act, 1996. The arbitration shall be conducted in Surat, Gujarat, in the English language.",
      },
      {
        title: "Changes to the Agreement",
        content:
          "PLACEAA reserves the right to modify this Agreement at any time. We will notify you of any significant changes by posting the new terms on our website and updating the effective date. Your continued use of our Services after such changes constitutes your acceptance of the new terms.",
      },
      {
        title: "Contact Us",
        content:
          "If you have any questions or concerns about this User Agreement, please contact us at:",
        contactInfo: [
          "PLACEAA",
          "Email: support@placeaa.com",
          "Phone: +91 90993 31142",
        ],
      },
    ],
    conclusion: "Thank you for trusting PLACEAA with your travel needs.",
  },
];

const UserAgreement = () => {
  const content = userAgreementContent[0];
  const isAboveMediumScreen = useMediaQuery({ minWidth: 768 });

  return (
    <>
      {!isAboveMediumScreen && <MobileNavbar />}

      <div className="w-full flex justify-center px-5 md:px-10 py-8 items-center">
        <div className="md:w-[70vw]">
          <h1 className="text-3xl font-bold mb-4 text-center">
            User Agreement
          </h1>
          <p className="text-sm mb-4">
            Effective Date: {content.effectiveDate}
          </p>

          <p className="text-lg font-semibold mb-2">Welcome to PLACEAA!</p>
          <p className="mb-4">{content.introduction}</p>

          {content.sections.map((section, index) => (
            <section key={index} className="mb-6">
              <h2 className="text-xl font-semibold mb-2">
                {index + 1}. {section.title}
              </h2>
              {Array.isArray(section.content) ? (
                section.content.map((item, i) => (
                  <p key={i} className="mb-2">
                    <span className="font-semibold">{item.subtitle}</span>{" "}
                    {item.text}
                  </p>
                ))
              ) : (
                <p>{section.content}</p>
              )}
              {section.contactInfo && (
                <div>
                  {section.contactInfo.map((info, i) => (
                    <p key={i}>{info}</p>
                  ))}
                </div>
              )}
            </section>
          ))}

          <p className="text-sm italic">{content.conclusion}</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserAgreement;
