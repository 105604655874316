import React, { useState } from "react";

interface SaveTravellersProps {
  profileData: any;
  //   setProfileData: (data: any) => void;
  //   additionalProfileData: any;
  //   setAdditionalProfileData: (data: any) => void;
}

const SaveTravellers: React.FC<SaveTravellersProps> = ({
  profileData,
  //   setProfileData,
  //   additionalProfileData,
  //   setAdditionalProfileData,
}) => {
  const [profileCard, setProfileCard] = useState<{
    travellers: Array<{
      firstName: string;
      lastName: string;
      gender: string;
      age: number;
      contact: { countryCode: string; number: string };
      email: string;
    }>;
  }>({
    travellers: [
      {
        firstName: "Shubh",
        lastName: "Mangukiya",
        gender: "Male",
        age: 20,
        email: "shubh.placeaa@gmail.com",
        contact: {
          countryCode: "+91",
          number: "7600081901",
        },
      },
      {
        firstName: "Justin",
        lastName: "Bieber",
        gender: "Male",
        age: 25,
        email: "justin.bieber@gmail.com",
        contact: {
          countryCode: "+91",
          number: "7600081901",
        },
      },
    ],
  });

  const getInitials = (name: string) => {
    const nameArray = name?.split(" ");
    if (nameArray?.length > 1) {
      return (
        nameArray[0]?.slice(0, 1) +
        nameArray[nameArray?.length - 1]?.slice(0, 1)
      );
    } else {
      return nameArray[0]?.slice(0, 1);
    }
  };

  return (
    <div
      id="profile-save-travellers"
      className="rounded-[20px] p-[25px] xl:p-[30px] border border-[#d9d9d9]"
      style={{ boxShadow: "0px 0px 10px 0px #D9D9D940" }}
    >
      <div className="flex flex-col md:flex-row md:items-center gap-2 justify-between">
        <div>
          <p className="font-[600] text-[18px] xl:text-[20px] xl:leading-[35px]">
            Save Travellers
          </p>
          <p className="font-[400] text-[14px] xl:text-[16px] xl:leading-[22px]">
            You have {profileData?.travellers?.length}{" "}
            {profileData?.travellers?.length > 1 ? "travellers" : "traveller"}
          </p>
        </div>
        <button className="border-[2px] font-[600] md:w-fit w-[50%]  text-[12px] md:text-[18px] xl:text-[20px] leading-[25px] border-[#082485] text-[#082485] bg-transparent rounded-full px-[25px] xl:px-[32px] py-[1.5px] xl:py-[2.4px]">
          Add Traveller
        </button>
      </div>
      <div className="mt-[21px] space-y-[10px] xl:space-y-[12px]">
        {profileCard?.travellers?.map((pct, idx) => (
          <div
            key={idx}
            className="flex flex-col md:flex-row md:items-center justify-between text-[14px] xl:text-[16px] font-[400] leading-[20px]"
          >
            <p className="border border-[#B9B9B9] w-fit text-center rounded-full text-[12px] xl:text-[18px] leading-[22px] py-[1.5px] xl:py-[6px] px-[7px] xl:px-[8px]">
              {getInitials(`${pct?.firstName} ${pct?.lastName}`)}
            </p>
            <div className="flex items-center gap-[5px] mt-1 md:mt-0">
              <p>
                {pct?.firstName} {pct?.lastName}
              </p>
              <p>
                ( {pct?.gender}, {pct?.age} Years )
              </p>
              77
            </div>
            <p>
              {pct?.contact?.countryCode}
              {pct?.contact?.number}
            </p>
            <p>{pct?.email}</p>
            <p className="text-[#082485] font-[600]">View Details</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SaveTravellers;
