import axios, { AxiosResponse } from "axios";
import { BASE_URL } from "./service.config";

interface SendOTPResponse {
  message: string;
}

interface SendOTPPayload {
  email?: string;
  phone?: string;
}

export interface VerifyOTPResponse {
  token: string;
  profileVerified: boolean;
}

interface VerifyOTPPayload {
  email?: string;
  phone?: string;
  otp: string;
}

interface CheckAuthResponse {
  message: string;
  profileVerified: boolean;
}

export const sendOTP = async (
  payload: SendOTPPayload
): Promise<SendOTPResponse> => {
  try {
    const url = `${BASE_URL}/api/auth/send-otp`;

    const response: AxiosResponse<SendOTPResponse> = await axios.post(
      url,
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error sending OTP:", error);
    throw error;
  }
};

export const verifyOTP = async (
  payload: VerifyOTPPayload
): Promise<VerifyOTPResponse> => {
  try {
    const url = `${BASE_URL}/api/auth/verify-otp`;
    const response: AxiosResponse<VerifyOTPResponse> = await axios.post(
      url,
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error verifying OTP:", error);
    throw error;
  }
};

export const checkAuth = async (): Promise<CheckAuthResponse> => {
  try {
    const url = `${BASE_URL}/api/auth/protected`;
    const token = localStorage.getItem("authToken");

    if (!token) {
      throw new Error("No authentication token found");
    }

    const response: AxiosResponse<CheckAuthResponse> = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error checking authentication:", error);
    throw error;
  }
};
