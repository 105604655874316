import axios from "axios";
import { BASE_URL } from "../service.config";

export const getPopularPlaces = async () => {
  try {
    const url = `${BASE_URL}/api/place/public-popular-places`;

    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    console.error("Error fetching popular places:", error);
    throw error;
  }
};
