import axios, { AxiosResponse } from "axios";
import { BASE_URL } from "./service.config";

interface ApplyBizConnectPayload {
  fullName: string;
  email: string;
  phone: string;
  companyPanDetails: string;
  panCardPdfUrl: string;
  companyName: string;
  natureOfBusiness: string;
  registrationDocument: string;
}

interface ApplyBizConnectResponse {
  message: string;
  applicationId: number;
}

interface VerifyOtpPayload {
  applicationId: number;
  code: string;
}

interface VerifyOtpResponse {
  message: string;
}

interface ApplicationStatusPayload {
  applicationId: number;
}

export interface ApplicationStatusResponse {
  status: {
    status: string;
    email: string;
    phone: string;
    emailVerified: boolean;
    phoneVerified: boolean;
  };
}


interface ResendOtpResponse {
  message: string;
}

export const applyBizConnect = async (
  payload: ApplyBizConnectPayload
): Promise<ApplyBizConnectResponse> => {
  try {
    const url = `${BASE_URL}/api/seller/apply-bizconnect`;

    const response: AxiosResponse<ApplyBizConnectResponse> = await axios.post(
      url,
      payload
    );

    return response.data;
  } catch (error) {
    console.error("Error applying for BizConnect:", error);
    throw error;
  }
};

export const verifyPhoneOtp = async (
  payload: VerifyOtpPayload
): Promise<VerifyOtpResponse> => {
  try {
    const url = `${BASE_URL}/api/seller/verify-phone-otp`;

    const response: AxiosResponse<VerifyOtpResponse> = await axios.post(
      url,
      payload
    );

    return response.data;
  } catch (error) {
    console.error("Error verifying phone OTP:", error);
    throw error;
  }
};

export const verifyEmailOtp = async (
  payload: VerifyOtpPayload
): Promise<VerifyOtpResponse> => {
  try {
    const url = `${BASE_URL}/api/seller/verify-email-otp`;

    const response: AxiosResponse<VerifyOtpResponse> = await axios.post(
      url,
      payload
    );

    return response.data;
  } catch (error) {
    console.error("Error verifying email OTP:", error);
    throw error;
  }
};

export const getApplicationStatus = async (
  payload: ApplicationStatusPayload
): Promise<ApplicationStatusResponse> => {
  try {
    const url = `${BASE_URL}/api/seller/application-status/${payload.applicationId}`;

    const response: AxiosResponse<ApplicationStatusResponse> = await axios.get(
      url,
      { params: payload }
    );

    return response.data;
  } catch (error) {
    console.error("Error getting application status:", error);
    throw error;
  }
};

export const resendEmailOtp = async (applicationId: number): Promise<ResendOtpResponse> => {
  try {
    const url = `${BASE_URL}/api/seller/resend-email-otp/${applicationId}`;
    const response: AxiosResponse<ResendOtpResponse> = await axios.post(url);
    return response.data;
  } catch (error) {
    console.error("Error resending email OTP:", error);
    throw error;
  }
};

export const resendPhoneOtp = async (applicationId: number): Promise<ResendOtpResponse> => {
  try {
    const url = `${BASE_URL}/api/seller/resend-phone-otp/${applicationId}`;
    const response: AxiosResponse<ResendOtpResponse> = await axios.post(url);
    return response.data;
  } catch (error) {
    console.error("Error resending phone OTP:", error);
    throw error;
  }
};
