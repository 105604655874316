import React, { useEffect, useState } from "react";
import { downClose } from "../../assets";
import { TailSpin } from "react-loader-spinner";
import {
  sendEmailVerificationOTP,
  sendPhoneVerificationOTP,
  verifyEmailOTP,
  verifyPhoneOTP,
} from "../../api/profile";

interface MyProfileProps {
  profileData: any;
  setProfileData: (data: any) => void;
  saveProfileData: () => void;
  updateStatus: any;
  isAnniversaryEnabled: boolean;
}

const MyProfile: React.FC<MyProfileProps> = ({
  profileData,
  setProfileData,
  saveProfileData,
  updateStatus,
  isAnniversaryEnabled,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [verificationType, setVerificationType] = useState<"phone" | "email">(
    "phone"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isEmailVerifying, setIsEmailVerifying] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState(false);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(re.test(email));
  };

  const validatePhone = (phone: string) => {
    setIsValidPhone(!!phone && phone.length === 10);
  };

  useEffect(() => {
    validateEmail(profileData?.email);
    validatePhone(profileData?.phone);
  }, [profileData?.email, profileData?.phone]);

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };

  const handleVerifyClick = async (type: "phone" | "email") => {
    setVerificationType(type);
    if (type === "phone" && !profileData?.phoneVerified) {
      if (profileData?.phone && profileData.phone.length === 10) {
        setIsVerifying(true);
        try {
          const response = await sendPhoneVerificationOTP(profileData.phone);
          if (
            response.message === "Phone verification OTP sent successfully."
          ) {
            setIsModalOpen(true);
          } else {
            alert("Failed to send OTP. Please try again.");
          }
        } catch (error) {
          console.error("Error sending phone verification OTP:", error);
          alert("Error sending OTP. Please try again.");
          setIsVerifying(false);
        }
      } else {
        alert("Please enter a valid 10-digit phone number.");
      }
    } else if (type === "email" && !profileData?.emailVerified) {
      setIsEmailVerifying(true);
      try {
        const response = await sendEmailVerificationOTP(profileData.email);
        if (response.message === "Email verification OTP sent successfully.") {
          setIsModalOpen(true);
        } else {
          alert("Failed to send OTP. Please try again.");
        }
      } catch (error) {
        console.error("Error sending email verification OTP:", error);
        alert("Error sending OTP. Please try again.");
        setIsEmailVerifying(false);
      } finally {
        setIsVerifying(false);
        setIsEmailVerifying(false);
      }
    }
  };

  const handleOtpSubmit = async () => {
    setIsLoading(true);
    try {
      let response;
      if (verificationType === "phone") {
        response = await verifyPhoneOTP(profileData.phone, otp);
      } else {
        response = await verifyEmailOTP(profileData.email, otp);
      }

      if (response.token) {
        setProfileData((prev: any) => ({
          ...prev,
          [`${verificationType}Verified`]: true,
        }));
        setIsModalOpen(false);
        alert(
          `${
            verificationType.charAt(0).toUpperCase() + verificationType.slice(1)
          } verified successfully.`
        );
      } else {
        alert("Failed to verify OTP. Please try again.");
      }
    } catch (error) {
      console.error(`Error verifying ${verificationType} OTP:`, error);
      alert("Error verifying OTP. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      id="profile-my-profile"
      className="rounded-[20px] p-[25px] xl:p-[30px] border border-[#d9d9d9]"
      style={{ boxShadow: "0px 0px 10px 0px #D9D9D940" }}
    >
      <div>
        <p className="text-[22px] font-[600]">My Profile</p>
        <div className="mt-[16px] xl:mt-[20px] grid grid-cols-1 md:grid-cols-2 gap-x-[6px] xl:gap-x-[8px] gap-y-[10px] xl:gap-y-[12px]">
          <div className="rounded-[10px] border border-[#d9d9d9] xl:px-[16px] xl:pb-[8px] px-[14px] pt-[8px] pb-[6px] xl:pt-[10px]">
            <p className="text-[12px] xl:text-[14px] font-[400] text-[#5e5e5e] leading-none">
              First & Middle Name
            </p>
            <input
              className="md:text-[16px]  xl:text-[18px] font-[400] w-full outline-none border-none"
              type="string"
              onChange={(e) =>
                setProfileData({
                  ...profileData,
                  firstName: e.target.value,
                })
              }
              value={profileData?.firstName}
              name="firstName"
            />
          </div>
          <div className="rounded-[10px] border border-[#d9d9d9] xl:px-[16px] xl:pb-[8px] px-[14px] pt-[8px] pb-[6px] xl:pt-[10px]">
            <p className="text-[12px] xl:text-[14px] font-[400] text-[#5e5e5e] leading-none">
              Last Name
            </p>
            <input
              className="text-[16px] xl:text-[18px] font-[400] w-full outline-none border-none"
              type="string"
              onChange={(e) =>
                setProfileData({
                  ...profileData,
                  lastName: e.target.value,
                })
              }
              value={profileData?.lastName}
              name="lastName"
            />
          </div>
          <div className="rounded-[10px] flex border items-center border-[#d9d9d9] xl:px-[16px] xl:pb-[8px] px-[14px] pt-[8px] pb-[6px] xl:pt-[10px]">
            <div className="flex items-center gap-[5px] xl:gap-[10px] pr-[19.5px]">
              <p className="text-[16px] xl:text-[18px] leading-none font-[400]">
                +91
              </p>
              <img
                className="xl:w-[11px] xl:h-[18px] w-[9px] h-[13px]"
                src={downClose}
                alt=""
              />
            </div>
            <div className="border-l pl-[10px] xl:pl-[15px] border-l-[#5E5E5E] w-full">
              <p className="text-[12px] xl:text-[14px] font-[400] text-[#5e5e5e] leading-none">
                Mobile Number
              </p>
              <input
                className="text-[16px] xl:text-[18px] font-[400] tracking-[1px] w-full outline-none border-none"
                type="number"
                max={10}
                maxLength={10}
                onChange={(e) => {
                  const value = e.target.value;
                  setProfileData((prev: any) => ({
                    ...prev,
                    phone: value,
                  }));
                  validatePhone(value);
                }}
                value={profileData?.phone}
                name="number"
                disabled={profileData?.phoneVerified}
              />
            </div>
            {!profileData?.phoneVerified && (
              <p
                className={`${
                  isValidPhone
                    ? "font-[400] underline text-[#5E5E5E] cursor-pointer"
                    : "font-[400] text-[#5E5E5E] opacity-50 cursor-not-allowed"
                } flex-1 text-right text-[16px] xl:text-[18px] leading-[22px]`}
                onClick={() => isValidPhone && handleVerifyClick("phone")}
              >
                {isVerifying ? (
                  <TailSpin
                    visible={true}
                    height="20"
                    width="20"
                    color="#000000"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                  />
                ) : (
                  "verify"
                )}
              </p>
            )}
            {profileData?.phoneVerified && (
              <p className="font-[600] text-[#13A180] flex-1 text-right text-[16px] xl:text-[18px] leading-[22px]">
                verified
              </p>
            )}
          </div>
          <div className="flex items-center rounded-[10px] border border-[#d9d9d9] xl:px-[16px] xl:pb-[8px] px-[14px] pt-[8px] pb-[6px] xl:pt-[10px]">
            <div className="w-full">
              <p className="text-[12px] xl:text-[14px] font-[400] text-[#5e5e5e] leading-none">
                Email Address
              </p>
              <input
                className="text-[16px] xl:text-[18px] font-[400] w-full outline-none border-none"
                type="email"
                onChange={(e) => {
                  const value = e.target.value;
                  setProfileData({
                    ...profileData,
                    email: value,
                  });
                  validateEmail(value);
                }}
                value={profileData?.email}
                name="email"
                disabled={profileData?.emailVerified}
              />
            </div>
            {!profileData?.emailVerified && (
              <p
                className={`${
                  isValidEmail
                    ? "font-[400] underline text-[#5E5E5E] cursor-pointer"
                    : "font-[400] text-[#5E5E5E] opacity-50 cursor-not-allowed"
                } flex-1 text-right text-[16px] xl:text-[18px] leading-[22px]`}
                onClick={() => isValidEmail && handleVerifyClick("email")}
              >
                {isEmailVerifying ? (
                  <TailSpin
                    visible={true}
                    height="20"
                    width="20"
                    color="#000000"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                  />
                ) : (
                  "verify"
                )}
              </p>
            )}
            {profileData?.emailVerified && (
              <p className="font-[600] text-[#13A180] flex-1 text-right text-[16px] xl:text-[18px] leading-[22px]">
                verified
              </p>
            )}
          </div>
          <div className="flex items-center rounded-[10px] border border-[#d9d9d9] xl:px-[16px] xl:pb-[9px] px-[14px] pt-[8px] pb-[6px] xl:pt-[12px]">
            <div className="w-full">
              <p className="text-[12px] xl:text-[14px] font-[400] text-[#5e5e5e] leading-none">
                Date Of Birth
              </p>
              <input
                className="text-[16px] xl:text-[18px] font-[400] w-full outline-none border-none"
                type="date"
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    dob: e.target.value,
                  })
                }
                placeholder="Date of Birth"
                value={profileData?.dob?.split("T")[0]}
                name="dateOfBirth"
              />
            </div>
          </div>
          <div className="flex items-center rounded-[10px] border border-[#d9d9d9] xl:px-[16px] xl:pb-[9px] px-[14px] pt-[8px] pb-[6px] xl:pt-[12px]">
            <div className="w-full">
              <p className="text-[12px] xl:text-[14px] font-[400] text-[#5e5e5e] leading-none">
                Nationality
              </p>
              <select
                className="text-[16px] xl:text-[18px] font-[400] w-full outline-none border-none"
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    nationality: e.target.value,
                  })
                }
                value={profileData?.nationality}
                name="nationality"
              >
                <option value="Indian">Indian</option>
                <option value="American">American</option>
                <option value="British">British</option>
              </select>
            </div>
          </div>
          <div className="flex justify-between items-center rounded-[10px] border border-[#d9d9d9] xl:px-[16px] xl:pb-[8px] px-[14px] pt-[8px] pb-[6px] xl:pt-[10px]">
            <div className="w-full">
              <p className="text-[12px] xl:text-[14px] font-[400] text-[#5e5e5e] leading-none">
                Marital Status
              </p>
              <select
                className="text-[16px] xl:text-[18px] font-[400] w-full outline-none border-none"
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    maritalStatus: e.target.value,
                  })
                }
                value={profileData?.maritalStatus}
                name="maritalStatus"
              >
                <option value="Single">Single</option>
                <option value="Married">Married</option>
                <option value="Divorced">Divorced</option>
              </select>
            </div>
          </div>
          <div className="flex items-center rounded-[10px] border border-[#d9d9d9] xl:px-[16px] xl:pb-[8px] px-[14px] pt-[8px] pb-[6px] xl:pt-[10px]">
            <div aria-disabled={!isAnniversaryEnabled} className="w-full">
              <p className="text-[12px] xl:text-[14px] font-[400] text-[#5e5e5e] leading-none">
                Anniversary Date
              </p>
              <input
                className="text-[16px] xl:text-[18px] font-[400] w-full outline-none border-none"
                type="date"
                onChange={(e) =>
                  setProfileData({
                    ...profileData,
                    anniversaryDate: e.target.value,
                  })
                }
                placeholder="Anniversary Date"
                value={profileData?.anniversaryDate?.split("T")[0]}
                disabled={!isAnniversaryEnabled}
                name="anniversary"
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-[22px] xl:mt-[35px]">
          <button
            onClick={saveProfileData}
            disabled={updateStatus.loading}
            className={`text-white bg-[#AB0101] font-inter text-[15px] md:text-[18px] xl:text-[20px] font-[500] leading-[26px] rounded-[10px] px-[45px] xl:px-[60px] py-[5px] xl:py-[8px] ${
              updateStatus.loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {updateStatus.loading ? (
              <TailSpin
                visible={true}
                height="24"
                width="24"
                color="#ffffff"
                ariaLabel="tail-spin-loading"
                radius="1"
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
      {updateStatus.error && (
        <p className="text-red-500 mt-2">{updateStatus.error}</p>
      )}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-[300px]">
            <h2 className="text-xl font-bold mb-4">Enter OTP</h2>
            <input
              type="number"
              value={otp}
              onChange={handleOtpChange}
              className="border p-2 rounded w-full"
              placeholder="Enter OTP"
            />
            <div className="flex justify-end mt-4">
              <button
                className="mr-2 px-4 py-2 border-[1px] border-gray-500 rounded hover:bg-gray-200"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-[#AB0101] text-white rounded"
                onClick={handleOtpSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <TailSpin
                    visible={true}
                    height="20"
                    width="20"
                    color="#ffffff"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                  />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyProfile;
