import React, { useEffect, useState } from "react";
import {
  getProfile,
  updateProfileImage,
  uploadProfileImageWithUrl,
} from "../../api/profile";
import DragAndDropFileUpload from "../file-upload/DragAndDropFileUpload";
import { TailSpin } from "react-loader-spinner";
import { profile } from "../../assets";

interface ProfileSidebarProps {
  profileData: any;
  sections: any[];
  selectedSection: string;
  changeSection: (name: string, navigator: any) => void;
  setProfileData: (data: any) => void;
}

const ProfileSidebar: React.FC<ProfileSidebarProps> = ({
  profileData,
  sections,
  selectedSection,
  changeSection,
  setProfileData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await getProfile();
        setProfileData(profile);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, [setProfileData]);

  const handleFileSelect = (file: File) => {
    setSelectedFile(file);
  };

  const handleUpload = async () => {
    if (selectedFile) {
      setIsUploading(true);
      try {
        const uploadData = await uploadProfileImageWithUrl(selectedFile);
        console.log("Image uploaded successfully:", uploadData);

        const updateData = await updateProfileImage(uploadData.url);
        console.log("Profile image updated successfully:", updateData);

        setProfileData((prev: any) => ({
          ...prev,
          profileImage: uploadData.url,
        }));
        setIsUploading(false);
        setIsModalOpen(false);
      } catch (error) {
        console.error("Error uploading or updating image:", error);
        setIsUploading(false);
      }
    }
  };

  return (
    <div>
      <div
        className="rounded-[20px] md:sticky top-24 h-fit py-[34px] px-[35px] border border-[#d9d9d9]"
        style={{ boxShadow: "0px 0px 10px 0px #D9D9D940" }}
      >
        <div className="flex gap-[15px] xl:gap-[20px] items-center">
          <div className="relative">
            <img
              className="xl:h-[90px] rounded-full h-[80px] w-[80px] xl:w-[90px] object-cover"
              src={profileData.profileImage || profile}
              alt=""
            />
            <p
              className="absolute text-white bg-black rounded-full xl:px-[11px] px-[8px] py-[1px] xl:py-[2px] bottom-0 right-0 text-[12px] xl:text-[13px] font-[600] leading-[15px] cursor-pointer"
              onClick={() => setIsModalOpen(true)}
            >
              Edit
            </p>
          </div>
          <div>
            <p className="text-black text-[22px] xl:text-[26px] font-[600] leading-[35px]">
              Hi {profileData?.firstName} !
            </p>
            <div className="text-[14px] xl:text-[16px] leading-[20px] flex items-center gap-[7px]">
              <p className="text-[#5E5E5E] font-[400]">{profileData?.email}</p>
              <p className="text-[#13A180] font-[600]">
                {profileData?.emailVerified && "verified"}
              </p>
            </div>
          </div>
        </div>
        <div className="flex mt-[25px] xl:mt-[40px] flex-col text-[16px] xl:text-[18px] font-[600] leading-[22px] text-black">
          {sections?.map((s) => (
            <button
              key={s?.name}
              onClick={() => changeSection(s?.name, s?.navigator)}
              className={`w-full ${
                selectedSection === s?.name ? "bg-[#AB0101] text-white" : ""
              } py-[10px] xl:py-[14px] rounded-[15px]`}
            >
              {s?.name}
            </button>
          ))}
          <button className="w-full py-[10px] xl:py-[14px] rounded-[15px]">
            Log Out
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg w-[500px] h-[350px] flex flex-col justify-between">
            <h2 className="text-xl font-bold mb-4">Upload Profile Image</h2>
            <DragAndDropFileUpload onFileSelect={handleFileSelect} />
            <div className="flex justify-end mt-4">
              <button
                className="mr-2 px-4 py-2 border-[1px] border-gray-500 rounded hover:bg-gray-200"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-[#AB0101] text-white rounded"
                onClick={handleUpload}
              >
                {isUploading ? (
                  <TailSpin
                    visible={true}
                    height="24"
                    width="24"
                    color="#ffffff"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                  />
                ) : (
                  "Upload"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileSidebar;
