const TERMS_AND_CONDITIONS = [
  {
    condition:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s. ",
  },
  {
    condition:
      "when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
  },
  {
    condition:
      "It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
  },
  {
    condition:
      "t was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.",
  },
  {
    condition:
      "more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  },
];
const PackageTermsAndConditions = () => {
  return (
    <div className="py-5">
      <div className="flex flex-col gap-5">
        <div className="bg-[#ab0101] w-full py-3 px-5 rounded-full">
          <p className="text-white font-medium">
            Terms & Conditions for travel package
          </p>
        </div>
        <div className="px-6 flex flex-col gap-5">
          {TERMS_AND_CONDITIONS.map((item) => (
            <li className="text-left">{item.condition}</li>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PackageTermsAndConditions;
