import React from "react";
import { calender } from "../../../assets";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

const BookingCard = ({
  prices,
  dates,
  itemId,
}: {
  prices: {
    original: number;
    discounted: number;
    discountedPercentage: number;
    checkpoints?: Array<string>;
  };
  dates: {
    from: string;
    to: string;
  };
  itemId: string;
}) => {
  const navigate = useNavigate();
  const isAboveMediumScreen = useMediaQuery({ minWidth: 768 });

  return (
    <div className="md:border min-w-[270px] 2xl:min-w-[320px] h-fit md:border-[#D9D9D9] pb-[15px] px-5 md:px-[15px] rounded-2xl md:shadow-[0px_0px_5px_0px_rgba(0,0,0,0.25)]">
      <div>
        <div className="flex  md:sticky top-0 justify-between items-center">
          <p className="font-inter mt-5 text-[15px] font-[500] tracking-[0.75px] text-[#B9B9B9] line-through">
            ₹{prices?.original}
          </p>
          <p className="bg-[#AB0101] rounded-[15px] pt-[4px] pb-[3px] px-[7px] font-inter text-[9.5px] font-[500] text-white">
            {prices?.discountedPercentage}% off
          </p>
        </div>
        <div className="flex border-b border-[#d9d9d9] pb-[12px] mt-2 items-center justify-between font-inter pr-[0px]">
          <div>
            <p className="text-[17px] font-[600] tracking-[1px] text-[#AB0101]">
              ₹{prices?.discounted}
            </p>
            <p className="text-[11px] -mt-1 font-[500] text-[#5e5e5e]">
              Per Person + taxes
            </p>
          </div>
          <div>
            {prices?.checkpoints?.map((pc: string, index: number) => (
              <li className="text-[9px] font-[500] text-[#5e5e5e]" key={index}>
                {pc}
              </li>
            ))}
          </div>
        </div>
        <div className="pt-[12px] pr-[9px] flex items-center justify-between">
          <div className="text-[#AB0101] flex items-center gap-[7px] text-[16px] font-[600]">
            <img
              src={calender}
              alt="calender"
              className="h-[17px] w-[18px] -mt-0.5 text-black"
            />
            <p>{dates?.from}</p>
            <p>-</p>
            <p>{dates?.to}</p>
          </div>
          <p className="text-[#3974CD] text-[16px] font-[600] tracking-[0.8px]">
            Change
          </p>
        </div>
        <button className="bg-[#AB0101E5] mt-[18px] w-full rounded-full py-[10px] text-white text-[15px] font-[600]">
          Request Callback
        </button>
        <button
          onClick={() => navigate(`/checkout/${itemId}`)}
          className="bg-[#AB0101E5] mt-[10px] w-full rounded-full py-[10px] text-white text-[15px] font-[600]"
        >
          Book Online
        </button>
      </div>

      {isAboveMediumScreen && (
        <div className="mt-[40px]">
          <p className="text-[16px] xl:text-[17px] font-[600]">
            Coupons & Offers
          </p>
          <div className="flex items-center p-[14px] xl:p-[16px] justify-between font-inter mt-[12px] xl:mt-[14px] border border-[#d9d9d9] rounded-[10px]">
            <p className="text-[12px] xl:text-[14px] font-[400]">
              Have a coupon code ?
            </p>
            <button className="text-[14px] xl:text-[15px] font-[600] text-[#3974CD]">
              Apply
            </button>
          </div>
          <p className="mt-2 cursor-pointer text-center text-[#AB0101] font-medium">
            Explore Available Offer
          </p>
        </div>
      )}
    </div>
  );
};

export default BookingCard;
