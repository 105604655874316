import { useMediaQuery } from "react-responsive";
import Footer from "../components/home/footer/Footer";
import {
  FaInstagram,
  FaFacebook,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import MobileNavbar from "../components/mobile-navbar/MobileNavbar";
import { mountains } from "../assets";

const ContactUs = () => {
  const isAboveMediumScreen = useMediaQuery({ minWidth: 768 });

  return (
    <>
      {!isAboveMediumScreen && <MobileNavbar />}

      <div className="w-full flex justify-center items-center">
        <div className="flex flex-col gap-10 px-5 md:px-10 sm:px-6 lg:px-8 py-10 md:py-12">
          <div className="flex md:flex-row flex-col gap-5 md:gap-10 items-center">
            <h1 className="text-6xl font-bold md:mb-2">Contact Us</h1>
            <p className="md:text-lg font-medium md:w-[60vw]">
              We'd love to hear from you! Whether you have a question, need
              support, or want to learn more about PLACEAA, our team is here to
              help.
            </p>
            <img
              src={mountains}
              alt="Scenic mountain view"
              className="w-full md:hidden h-40 object-cover md:mb-6 rounded-2xl"
            />
          </div>

          <div className="flex flex-col md:flex-row md:gap-8">
            <h2 className="md:hidden text-2xl font-bold">Get in Touch</h2>

            <div className="w-full md:w-1/2">
              <form className="md:bg-[#F5F5F5] py-8 md:px-14 rounded-3xl md:shadow-xl md:border border-[#B9B9B9]">
                <div className="md:mb-6 mb-4 flex flex-col md:flex-row md:items-center">
                  <label
                    htmlFor="name"
                    className="w-1/3 md:text-lg font-medium"
                  >
                    Your Name*
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="md:w-2/3 p-1 border border-[#B9B9B9] rounded-md"
                    required
                  />
                </div>
                <div className="md:mb-6 mb-4 flex flex-col md:flex-row md:items-center">
                  <label
                    htmlFor="email"
                    className="w-1/3 md:text-lg font-medium"
                  >
                    Email ID*
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="md:w-2/3 p-1 border border-[#B9B9B9] rounded-md"
                    required
                  />
                </div>
                <div className="md:mb-6 mb-4 flex flex-col md:flex-row md:items-center">
                  <label
                    htmlFor="phone"
                    className="md:w-1/3 md:text-lg font-medium"
                  >
                    Contact Number*
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    className="md:w-2/3 p-1 border border-[#B9B9B9] rounded-md"
                    required
                  />
                </div>
                <div className="md:mb-6 mb-4 flex flex-col gap-1 md:gap-0 md:flex-row md:items-center">
                  <label
                    htmlFor="file"
                    className="w-1/3 md:text-lg font-medium"
                  >
                    Upload a file
                  </label>
                  <div className="md:w-2/3">
                    <button
                      type="button"
                      className="bg-[#ab0101] text-white px-10 py-1 rounded-md hover:bg-red-700"
                      onClick={() => document?.getElementById("file")?.click()}
                    >
                      Browse
                    </button>
                    <input type="file" id="file" className="hidden" />
                  </div>
                </div>
                <div className="md:mb-6 mb-2">
                  <label
                    htmlFor="message"
                    className="block mb-2 md:text-lg font-medium"
                  >
                    Questions / Comments
                  </label>
                  <textarea
                    id="message"
                    rows={4}
                    className="w-full p-2 border border-[#B9B9B9] rounded-md"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="w-full bg-[#ab0101] text-white py-1 rounded-md text-lg font-medium hover:bg-red-700"
                >
                  Submit Now
                </button>
              </form>
            </div>

            <div className="w-full md:w-1/2">
              <h2 className="hidden md:block text-2xl font-semibold mb-4">
                Get in Touch
              </h2>
              <p className="mb-2 text-xl font-semibold">Email:</p>
              <p className="mb-4 text-lg">
                For any inquiries, please email us at:{" "}
                <span className="font-semibold text-[#ab0101]">
                  support@placeaa.com
                </span>
              </p>

              <p className="mb-2 text-xl font-semibold">Phone:</p>
              <p className="mb-4 text-lg">Call us at: +91 90993 31142</p>

              <p className="mb-2 text-xl font-semibold">Social Media:</p>
              <div className="flex space-x-4 mb-4">
                <a
                  href="https://www.instagram.com/placeaa_"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram size={23} />
                </a>
                <a
                  href="https://www.facebook.com/placeaa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook size={23} />
                </a>
                <a
                  href="https://x.com/placeaa_"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaXTwitter size={23} />
                </a>
                <a
                  href="https://www.linkedin.com/company/placeaa/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedinIn size={23} />
                </a>
              </div>

              <p className="text-lg mt-10">
                We look forward to connecting with you!
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
