export const preloadImages = (srcs: string[]): Promise<void[]> => {
  const preloadPromises = srcs.map((src) => {
    return new Promise<void>((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve();
      img.onerror = reject;
      img.src = src;
    });
  });

  return Promise.all(preloadPromises);
};
