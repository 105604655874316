import React, { useState } from "react";
import { getApplicationStatus, ApplicationStatusResponse, resendEmailOtp, resendPhoneOtp, verifyEmailOtp, verifyPhoneOtp } from "../../api/bizConnect";
import { TailSpin } from "react-loader-spinner";

const BizConnectStatusCheck = () => {
  const [statusApplicationId, setStatusApplicationId] = useState<number | null>(null);
  const [applicationStatus, setApplicationStatus] = useState<ApplicationStatusResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [resendingEmail, setResendingEmail] = useState(false);
  const [resendingPhone, setResendingPhone] = useState(false);
  const [emailOtp, setEmailOtp] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const [verifyingEmail, setVerifyingEmail] = useState(false);
  const [verifyingPhone, setVerifyingPhone] = useState(false);

  const handleCheckStatus = async () => {
    if (!statusApplicationId) {
      alert("Please enter an application ID");
      return;
    }

    setIsLoading(true);
    try {
      const response = await getApplicationStatus({ applicationId: statusApplicationId });
      setApplicationStatus(response);
      if (response.status.status === "PROCESSING") {
        await handleResendEmailOtp();
        await handleResendPhoneOtp();
      }
    } catch (error) {
      console.error("Error checking application status:", error);
      alert("Failed to check application status. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendEmailOtp = async () => {
    if (!statusApplicationId) return;
    setResendingEmail(true);
    try {
      const response = await resendEmailOtp(statusApplicationId);
      alert(response.message);
    } catch (error) {
      console.error("Error resending email OTP:", error);
      alert("Failed to resend email OTP. Please try again.");
    } finally {
      setResendingEmail(false);
    }
  };

  const handleResendPhoneOtp = async () => {
    if (!statusApplicationId) return;
    setResendingPhone(true);
    try {
      const response = await resendPhoneOtp(statusApplicationId);
      alert(response.message);
    } catch (error) {
      console.error("Error resending phone OTP:", error);
      alert("Failed to resend phone OTP. Please try again.");
    } finally {
      setResendingPhone(false);
    }
  };

  const handleVerifyEmailOtp = async () => {
    if (!statusApplicationId || !emailOtp) return;
    setVerifyingEmail(true);
    try {
      const response = await verifyEmailOtp({ applicationId: statusApplicationId, code: emailOtp });
      alert(response.message);
      // Refresh application status after verification
      await handleCheckStatus();
    } catch (error) {
      console.error("Error verifying email OTP:", error);
      alert("Failed to verify email OTP. Please try again.");
    } finally {
      setVerifyingEmail(false);
    }
  };

  const handleVerifyPhoneOtp = async () => {
    if (!statusApplicationId || !phoneOtp) return;
    setVerifyingPhone(true);
    try {
      const response = await verifyPhoneOtp({ applicationId: statusApplicationId, code: phoneOtp });
      alert(response.message);
      // Refresh application status after verification
      await handleCheckStatus();
    } catch (error) {
      console.error("Error verifying phone OTP:", error);
      alert("Failed to verify phone OTP. Please try again.");
    } finally {
      setVerifyingPhone(false);
    }
  };

  return (
    <div className="grid grid-cols-[auto,1fr] items-center gap-x-[22px] xl:gap-x-[26px] gap-y-[20px]">
      <label
        className="w-fit text-[14px] xl:text-[18px] font-[400]"
        htmlFor="statusApplicationId"
      >
        Application ID <span className="text-red-500">*</span>
      </label>
      <input
        className="rounded-[8px] border border-black px-1 xl:px-2 py-0.5 xl:py-1 outline-none"
        type="text"
        id="statusApplicationId"
        value={statusApplicationId ?? ''}
        onChange={(e) => {
          const value = e.target.value;
          setStatusApplicationId(value === '' ? null : Number(value));
        }}
        required
      />
      {applicationStatus && !applicationStatus?.status?.emailVerified && (
        <>
        <label
        className="w-fit text-[14px] xl:text-[18px] font-[400]"
        htmlFor="statusApplicationId"
      >
        Email OTP
      </label>
            <div className="flex items-center mt-2 mb-4">
              <input
                type="text"
                value={emailOtp}
                onChange={(e) => setEmailOtp(e.target.value)}
                placeholder="Enter Email OTP"
                className="rounded-[8px] border border-black px-1 xl:px-2 py-0.5 xl:py-1 outline-none mr-2"
              />
              <button
                onClick={handleVerifyEmailOtp}
                disabled={verifyingEmail}
                className="bg-[#AB0101] text-white px-2 py-1 rounded-md w-full flex justify-center items-center"
                >
                {verifyingEmail ? 'Verifying...' : 'Verify'}
              </button>
            </div>
        </>
          )}
           {applicationStatus && !applicationStatus?.status?.phoneVerified && (
            <>
             <label
             className="w-fit text-[14px] xl:text-[18px] font-[400]"
             htmlFor="statusApplicationId"
           >
             Phone OTP
           </label>
            <div className="flex items-center mt-2 mb-4">
              <input
                type="text"
                value={phoneOtp}
                onChange={(e) => setPhoneOtp(e.target.value)}
                placeholder="Enter Phone OTP"
                className="rounded-[8px] border border-black px-1 xl:px-2 py-0.5 xl:py-1 outline-none mr-2"
              />
              <button
                onClick={handleVerifyPhoneOtp}
                disabled={verifyingPhone}
                className="bg-[#AB0101] text-white px-2 py-1 w-full rounded-md flex justify-center items-center"
                >
                {verifyingPhone ? 'Verifying...' : 'Verify'}
              </button>
            </div>
            </>
          )}
      <div className="col-span-2">
        <button
          type="button"
          onClick={handleCheckStatus}
          className="font-inter flex justify-center items-center mt-5 text-[12px] xl:text-[16px] font-[500] text-white bg-[#AB0101] py-[5px] xl:py-[8px] w-full rounded-[10px]"
          disabled={isLoading}
        >
          {isLoading ? (
            <TailSpin
              visible={true}
              height="20"
              width="20"
              color="#FFFFFF"
              ariaLabel="tail-spin-loading"
              radius="1"
            />
          ) : (
            "Check Status"
          )}
        </button>
      </div>
      {applicationStatus && (
        <div className="col-span-2 mt-4">
          <h3 className="text-[16px] xl:text-[20px] font-[600] mb-2">Application Status</h3>
          <p className="text-[14px] xl:text-[16px] mb-1"><strong>Status:</strong> {applicationStatus.status.status}</p>
          <p className="text-[14px] xl:text-[16px] mb-1">
            <strong>Email:</strong> {applicationStatus.status.email} 
            ({applicationStatus.status.emailVerified ? 'Verified' : 'Not Verified'})
            {!applicationStatus.status.emailVerified && (
              <button
                onClick={handleResendEmailOtp}
                disabled={resendingEmail}
                className="text-[#AB0101] text-sm underline ml-2"
                >
                {resendingEmail ? 'Resending...' : 'Resend OTP'}
              </button>
            )}
          </p>
          <p className="text-[14px] xl:text-[16px] mb-1">
            <strong>Phone:</strong> {applicationStatus.status.phone} 
            ({applicationStatus.status.phoneVerified ? 'Verified' : 'Not Verified'})
            {!applicationStatus.status.phoneVerified && (
              <button
                onClick={handleResendPhoneOtp}
                disabled={resendingPhone}
                className="text-[#AB0101] text-sm underline ml-2"
                >
                {resendingPhone ? 'Resending...' : 'Resend OTP'}
              </button>
            )}
          </p>
         
        </div>
      )}
    </div>
  );
};

export default BizConnectStatusCheck;