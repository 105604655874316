import React from "react";
import { TailSpin } from "react-loader-spinner";
import { downClose } from "../../assets";

interface GSTDetailsProps {
  additionalProfileData: any;
  setAdditionalProfileData: (data: any) => void;
  saveAdditionalProfileData: () => void;
  additionalProfileUpdateStatus: any;
}

const GSTDetails: React.FC<GSTDetailsProps> = ({
  additionalProfileData,
  setAdditionalProfileData,
  saveAdditionalProfileData,
  additionalProfileUpdateStatus,
}) => {
  return (
    <div
      id="profile-gst-details"
      className="rounded-[20px] p-[25px] xl:p-[30px] border border-[#d9d9d9]"
      style={{ boxShadow: "0px 0px 10px 0px #D9D9D940" }}
    >
      <div>
        <p className="text-[22px] font-[600]">GST Details</p>
        <div className="mt-[16px] xl:mt-[20px] grid grid-cols-1 md:grid-cols-2 gap-x-[6px] xl:gap-x-[8px] gap-y-[10px] xl:gap-y-[12px]">
          <div className="rounded-[10px] border border-[#d9d9d9] xl:px-[16px] xl:pb-[8px] px-[14px] pt-[8px] pb-[6px] xl:pt-[10px]">
            <p className="text-[12px] xl:text-[14px] font-[400] text-[#5e5e5e] leading-none">
              GST Number
            </p>
            <input
              className="text-[16px] xl:text-[18px] font-[400] w-full outline-none border-none"
              type="string"
              onChange={(e) =>
                setAdditionalProfileData({
                  ...additionalProfileData,
                  gstNumber: e.target.value,
                })
              }
              value={additionalProfileData?.gstNumber}
              name="gstNumber"
            />
          </div>
          <div className="rounded-[10px] border border-[#d9d9d9] xl:px-[16px] xl:pb-[8px] px-[14px] pt-[8px] pb-[6px] xl:pt-[10px]">
            <p className="text-[12px] xl:text-[14px] font-[400] text-[#5e5e5e] leading-none">
              Company Name
            </p>
            <input
              className="text-[16px] xl:text-[18px] font-[400] w-full outline-none border-none"
              type="string"
              onChange={(e) =>
                setAdditionalProfileData((prev: any) => ({
                  ...additionalProfileData,
                  companyName: e.target.value,
                }))
              }
              value={additionalProfileData?.companyName}
              name="companyName"
            />
          </div>
          <div className="rounded-[10px] flex border items-center border-[#d9d9d9] xl:px-[16px] xl:pb-[8px] px-[14px] pt-[8px] pb-[6px] xl:pt-[10px]">
            <div className="flex items-center gap-[5px] xl:gap-[10px] pr-[19px]">
              <p className="text-[16px] xl:text-[18px] leading-none font-[400]">
                +91
              </p>
              <img
                className="xl:w-[11px] xl:h-[18px] w-[8px] h-[12px]"
                src={downClose}
                alt=""
              />
            </div>
            <div className="border-l pl-[10px] xl:pl-[19px] border-l-[#5E5E5E]">
              <p className="text-[12px] xl:text-[14px] font-[400] text-[#5e5e5e] leading-none">
                Mobile Number
              </p>
              <input
                className="text-[16px] xl:text-[18px] font-[400] tracking-[1px] w-full outline-none border-none"
                type="number"
                max={10}
                maxLength={10}
                onChange={(e) =>
                  setAdditionalProfileData((prev: any) => ({
                    ...additionalProfileData,
                    phone: e.target.value,
                  }))
                }
                value={additionalProfileData?.phone}
                name="number"
              />
            </div>
            <p
              className={`${
                additionalProfileData?.phoneVerified
                  ? "font-[600] text-[#13A180]"
                  : "font-[400] underline text-[#5E5E5E] cursor-pointer"
              } flex-1 text-right text-[16px] xl:text-[18px] leading-[25px]`}
            >
              {additionalProfileData?.phoneVerified ? "verified" : "verify"}
            </p>
          </div>
          <div className="flex items-center rounded-[10px] border border-[#d9d9d9] xl:px-[16px] xl:pb-[8px] px-[14px] pt-[8px] pb-[6px] xl:pt-[10px]">
            <div className="w-full">
              <p className="text-[12px] xl:text-[14px] font-[400] text-[#5e5e5e] leading-none">
                Email Address
              </p>
              <input
                className="text-[16px] xl:text-[18px] font-[400] w-full outline-none border-none"
                type="email"
                onChange={(e) =>
                  setAdditionalProfileData((prev: any) => ({
                    ...additionalProfileData,
                    email: e.target.value,
                  }))
                }
                value={additionalProfileData?.email}
                name="email"
              />
            </div>
            <p
              className={`${
                additionalProfileData?.emailVerified
                  ? "font-[600] text-[#13A180]"
                  : "font-[400] underline text-[#5E5E5E] cursor-pointer"
              } flex-1 text-right text-[16px] xl:text-[18px] leading-[25px]`}
            >
              {additionalProfileData?.emailVerified ? "verified" : "verify"}
            </p>
          </div>
          <div className="flex items-center rounded-[10px] border border-[#d9d9d9] xl:px-[16px] xl:pb-[8px] px-[14px] pt-[8px] pb-[6px] xl:pt-[10px]">
            <div className="w-full">
              <p className="text-[12px] xl:text-[14px] font-[400] text-[#5e5e5e] leading-none">
                Address
              </p>
              <input
                className="text-[16px] xl:text-[18px] font-[400] w-full outline-none border-none"
                type="string"
                onChange={(e) =>
                  setAdditionalProfileData({
                    ...additionalProfileData,
                    address: e.target.value,
                  })
                }
                value={additionalProfileData?.address}
                name="address"
              />
            </div>
          </div>
          <div className="rounded-[10px] flex items-center justify-between border border-[#d9d9d9] xl:px-[16px] xl:pb-[8px] px-[14px] pt-[8px] pb-[6px] xl:pt-[10px]">
            <div>
              <p className="text-[12px] xl:text-[14px] font-[400] text-[#5e5e5e] leading-none">
                Pincode
              </p>
              <input
                className="text-[16px] xl:text-[18px] font-[400] w-full outline-none border-none"
                type="string"
                onChange={(e) =>
                  setAdditionalProfileData({
                    ...additionalProfileData,
                    pincode: e.target.value,
                  })
                }
                value={additionalProfileData?.pincode}
                name="pincode"
              />
            </div>
            {/* <p className="text-black font-[400] xl:leading-[25px] text-[14px] xl:text-[18px]">
                      {inputData?.location?.city}, {inputData?.location?.state}
                    </p> */}
          </div>
        </div>
        <div className="flex justify-end mt-[22px] xl:mt-[35px]">
          <button
            onClick={saveAdditionalProfileData}
            className="text-white bg-[#AB0101] font-inter text-[15px] md:text-[18px] xl:text-[20px] font-[500] leading-[26px] rounded-[10px] px-[45px] xl:px-[60px] py-[5px] xl:py-[8px]"
          >
            {additionalProfileUpdateStatus.loading ? (
              <TailSpin
                visible={true}
                height="24"
                width="24"
                color="#ffffff"
                ariaLabel="tail-spin-loading"
                radius="1"
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default GSTDetails;
