import axios, { AxiosResponse } from "axios";
import { BASE_URL } from "./service.config";

interface ProfileResponse {
  profileVerified: boolean;
  id: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  dob: string;
  nationality: string;
  maritalStatus: string;
  anniversaryDate?: string | null;
  gstNumber?: string;
  companyName?: string;
  address?: string;
  pincode?: string;
  profileImage?: string | null;
  email: string;
  phone?: string | null;
  emailVerified: boolean;
  phoneVerified: boolean;
}

interface UpdateBasicProfilePayload {
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  nationality: string;
  maritalStatus: string;
  anniversaryDate?: string;
}

interface UpdateBasicProfileResponse {
  message: string;
}

interface UpdateAdditionalProfilePayload {
  gstNumber?: string;
  companyName?: string;
  address?: string;
  pincode?: string;
}

interface UpdateAdditionalProfileResponse {
  message: string;
}

export const getProfile = async (): Promise<ProfileResponse> => {
  try {
    const url = `${BASE_URL}/api/profile`;
    const token = localStorage.getItem("authToken");

    if (!token) {
      throw new Error("No authentication token found");
    }

    const response: AxiosResponse<ProfileResponse> = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching profile:", error);
    throw error;
  }
};

export const updateBasicProfile = async (
  profileData: UpdateBasicProfilePayload
): Promise<UpdateBasicProfileResponse> => {
  try {
    const url = `${BASE_URL}/api/profile/basic`;
    const token = localStorage.getItem("authToken");

    if (!token) {
      throw new Error("No authentication token found");
    }

    const response: AxiosResponse<UpdateBasicProfileResponse> = await axios.put(
      url,
      profileData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error updating basic profile:", error);
    throw error;
  }
};

export const updateAdditionalProfile = async (
  additionalProfileData: UpdateAdditionalProfilePayload
): Promise<UpdateAdditionalProfileResponse> => {
  try {
    const url = `${BASE_URL}/api/profile/additional`;
    const token = localStorage.getItem("authToken");

    if (!token) {
      throw new Error("No authentication token found");
    }

    const response: AxiosResponse<UpdateAdditionalProfileResponse> =
      await axios.put(url, additionalProfileData, {
        headers: {
          Authorization: token,
        },
      });

    return response.data;
  } catch (error) {
    console.error("Error updating additional profile:", error);
    throw error;
  }
};

export const uploadProfileImageWithUrl = async (file: File) => {
  const url = `${BASE_URL}/api/auth/upload-image`;
  const formData = new FormData();
  formData.append("image", file);
  const token = localStorage.getItem("authToken");

  try {
    const response = await axios.post(url, formData, {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

export const updateProfileImage = async (imageUrl: string) => {
  const url = `${BASE_URL}/api/profile/image`;
  const token = localStorage.getItem("authToken");

  try {
    const response = await axios.put(
      url,
      { profileImage: imageUrl },
      {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error updating profile image:", error);
    throw error;
  }
};

export const sendPhoneVerificationOTP = async (phone: string) => {
  const token = localStorage.getItem("authToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/auth/send-phone-verification-otp`,
      { phone },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending phone verification OTP:", error);
    throw error;
  }
};

export const sendEmailVerificationOTP = async (email: string) => {
  const token = localStorage.getItem("authToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/auth/send-email-verification-otp`,
      { email },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error sending email verification OTP:", error);
    throw error;
  }
};

export const verifyPhoneOTP = async (phone: string, code: string) => {
  const token = localStorage.getItem("authToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/auth/verify-phone-otp`,
      { phone, code },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error verifying phone OTP:", error);
    throw error;
  }
};

export const verifyEmailOTP = async (email: string, code: string) => {
  const token = localStorage.getItem("authToken");

  try {
    const response = await axios.post(
      `${BASE_URL}/api/auth/verify-email-otp`,
      { email, code },
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error verifying email OTP:", error);
    throw error;
  }
};
