import { useEffect, useState } from "react";
import { banner } from "../assets";
import SponsoredAd from "../components/home/ads/SponsoredAd";
import HolidayPackage from "../components/home/holiday_package/HolidayPackage";
import { getPublicAds } from "../api/home/ads";
import ThemeHolidayPackages from "../components/allHolidayPackages/ThemeHolidayPackages";
import HelpCenter from "../components/home/help_center/HelpCenter";
import BestHoneyMoonPackages from "../components/allHolidayPackages/BestHoneyMoonPackages";
import PersonalizedTravelPackageForm from "../components/home/package_form/PersonalizedTravelPackageForm";
import About from "../components/home/about/About";
import Footer from "../components/home/footer/Footer";
import MobileNavbar from "../components/mobile-navbar/MobileNavbar";
import { useMediaQuery } from "react-responsive";

interface Ad {
  id: number;
  banner: string;
  link: string;
  views: number;
  clickCount: number;
  addedBy: number;
  createdAt: string;
}

const AllHolidayPackages = () => {
  const [ads, setAds] = useState<Ad[]>([]);
  const isAboveMediumScreen = useMediaQuery({ minWidth: 768 });

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await getPublicAds();
        setAds(response);
      } catch (error) {
        console.error("Error fetching public ads:", error);
      }
    };

    fetchAds();
  }, []);
  return (
    <>
      {!isAboveMediumScreen && <MobileNavbar />}

      <div className="flex flex-col gap-5">
        <div className="md:px-10 px-5 md:pt-10 pt-6 w-full flex justify-center items-center rounded-xl">
          <img src={banner} alt="banner" className="object-cover rounded-xl" />
        </div>
        <HolidayPackage />
        <div className="mt-[70px] md:mx-0 mx-3">
          {ads.length > 0 && <SponsoredAd ad={ads[0]} />}
        </div>
        <ThemeHolidayPackages />
        <HelpCenter />
        <BestHoneyMoonPackages />
        <div className="mt-[70px] md:mx-0 mx-3">
          {ads.length > 0 && <SponsoredAd ad={ads[0]} />}
        </div>
        <div className="mb-[20px]" />
        <PersonalizedTravelPackageForm />
        <div className="mb-[40px]" />
        <About />
        <Footer />
      </div>
    </>
  );
};

export default AllHolidayPackages;
