import {
  adventure_theme,
  family,
  honeymoon,
  mountain_profile,
  religious_theme,
  solo_theme,
} from "../../assets";

const THEMES = [
  {
    name: "Adventure",
    image: adventure_theme,
  },
  {
    name: "Religious",
    image: religious_theme,
  },
  {
    name: "Homeymoon",
    image: honeymoon,
  },
  {
    name: "Group/Family",
    image: family,
  },
  {
    name: "Solo",
    image: solo_theme,
  },
];

const ThemeHolidayPackages = () => {
  return (
    <div className="md:px-10 md:py-10 py-5 flex flex-col gap-5 w-full justify-center items-center">
      <div className="inline-flex md:text-3xl text-xl gap-2">
        <span className="text-[#AB0101] font-semibold">Theme For </span>
        <span className="font-semibold">Holiday Package</span>
      </div>
      <div className="md:flex md:flex-row grid grid-cols-2 gap-5">
        {THEMES.map((theme) => (
          <div
            className="relative h-40 w-40 md:h-64 md:w-64 flex justify-start items-end p-3 rounded-2xl bg-cover bg-no-repeat bg-center"
            style={{ backgroundImage: `url(${theme.image})` }}
          >
            <div className="absolute inset-0 h-full w-full bg-black bg-opacity-30 rounded-2xl" />
            <div className="inline-flex items-center gap-2 z-10">
              <img
                src={mountain_profile}
                alt="profile"
                className="md:h-10 md:w-10 h-8 w-8"
              />
              <p className="text-white font-medium md:text-lg">{theme.name}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ThemeHolidayPackages;
