import { customizered, downloadred } from "../../../assets";

const TripCustomization = () => {
  return (
    <div className="bg-[#FFF2D1] flex gap-4 w-full justify-center items-center rounded-2xl py-4">
      <div className="flex flex-col items-center gap-[8px]">
        <img
          className="w-5 md:w-full xl:w-[30px] lg:w-[25px] lg:h-[15px] xl:h-[20px] 2xl:w-[35px] 2xl:h-[25px]"
          src={customizered}
          alt="customize"
        />
        <p className="text-center font-semibold">Customize your trip</p>
      </div>
      <div className=" h-10 w-0.5 bg-black rounded-full" />
      <div className="flex flex-col items-center gap-[8px]">
        <img
          className="w-4 md:w-full xl:w-[30px] lg:w-[25px] lg:h-[15px] xl:h-[20px] 2xl:w-[35px] 2xl:h-[25px]"
          src={downloadred}
          alt="customize"
        />
        <p className="text-center font-semibold">Download trip details</p>
      </div>
    </div>
  );
};

export default TripCustomization;
