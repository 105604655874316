import { useEffect, useState } from "react";
import { profile } from "../assets";
import MobileNavbar from "../components/mobile-navbar/MobileNavbar";
import { useMediaQuery } from "react-responsive";
import About from "../components/home/about/About";
import Footer from "../components/home/footer/Footer";
import {
  getProfile,
  updateAdditionalProfile,
  updateBasicProfile,
} from "../api/profile";
import Spinner from "../components/common/Spinner";
import GSTDetails from "../components/profile/GSTDetails";
import SaveTravellers from "../components/profile/SaveTravellers";
import ProfileSidebar from "../components/profile/ProfileSidebar";
import MyProfile from "../components/profile/MyProfile";

const Profile = () => {
  const [profileData, setProfileData] = useState({
    profileImage: profile,
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    nationality: "",
    maritalStatus: "",
    anniversaryDate: "",
    email: "",
    phone: "",
    emailVerified: false,
    phoneVerified: false,
  });

  const [additionalProfileData, setAdditionalProfileData] = useState({
    gstNumber: "",
    companyName: "",
    address: "",
    pincode: "",
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoading(true);
        const data = await getProfile();

        setProfileData({
          profileImage: data.profileImage || profile,
          firstName: data.firstName || "",
          middleName: data.middleName || "",
          lastName: data.lastName || "",
          dob: data.dob || "",
          nationality: data.nationality || "",
          maritalStatus: data.maritalStatus || "",
          anniversaryDate: data.anniversaryDate || "",
          email: data.email || "",
          phone: data.phone || "",
          emailVerified: data.emailVerified || false,
          phoneVerified: data.phoneVerified || false,
        });

        setAdditionalProfileData({
          gstNumber: data.gstNumber || "",
          companyName: data.companyName || "",
          address: data.address || "",
          pincode: data.pincode || "",
        });
      } catch (err) {
        console.error("Error fetching profile:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const [updateStatus, setUpdateStatus] = useState<{
    loading: boolean;
    error: string | null;
  }>({
    loading: false,
    error: null,
  });

  const [additionalProfileUpdateStatus, setAdditionalProfileUpdateStatus] =
    useState<{
      loading: boolean;
      error: string | null;
    }>({
      loading: false,
      error: null,
    });

  const [selectedSection, setSelectedSection] = useState<string>("My Profile");
  const isAboveMediumScreen = useMediaQuery({ minWidth: 768 });
  const [isAnniversaryEnabled, setIsAnniversaryEnabled] = useState(false);

  useEffect(() => {
    if (profileData.maritalStatus === "Married") {
      setIsAnniversaryEnabled(true);
    } else {
      setIsAnniversaryEnabled(false);
      setProfileData((prevData) => ({
        ...prevData,
        anniversaryDate: "",
      }));
    }
  }, [profileData.maritalStatus]);

  const sections = [
    { name: "My Profile", navigator: "profile-my-profile" },
    { name: "GST Details", navigator: "profile-gst-details" },
    { name: "Save Travellers", navigator: "profile-save-travellers" },
  ];

  const formatDate = (dateString: string): string => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0] + "T00:00:00Z";
  };

  const saveProfileData = async () => {
    setUpdateStatus({ loading: true, error: null });
    try {
      const updatedProfile = {
        ...profileData,
        dob: formatDate(profileData.dob),
        anniversaryDate: formatDate(profileData.anniversaryDate),
      };

      await updateBasicProfile(updatedProfile);
      setUpdateStatus({ loading: false, error: null });
      console.log("Profile updated successfully");
    } catch (error) {
      setUpdateStatus({ loading: false, error: "Error updating profile" });
      console.error("Error updating profile:", error);
    }
  };

  const saveAdditionalProfileData = async () => {
    setAdditionalProfileUpdateStatus({ loading: true, error: null });
    try {
      const updatedAdditionalProfile = {
        ...additionalProfileData,
      };

      await updateAdditionalProfile(updatedAdditionalProfile);
      setAdditionalProfileUpdateStatus({ loading: false, error: null });
      console.log("Profile updated successfully");
    } catch (error) {
      setAdditionalProfileUpdateStatus({
        loading: false,
        error: "Error updating profile",
      });
      console.error("Error updating profile:", error);
    }
  };

  const changeSection = (name: string, navigator: string) => {
    setSelectedSection(name);
    const element = document.getElementById(navigator);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      {!isAboveMediumScreen && <MobileNavbar />}
      {loading ? (
        <Spinner />
      ) : (
        <div className="px-[25px] xl:px-[40px] pb-[30px] pt-[20px] xl:pt-[30px] flex flex-col md:flex-row gap-[20px] xl:gap-[30px]">
          <ProfileSidebar
            profileData={profileData}
            sections={sections}
            selectedSection={selectedSection}
            changeSection={changeSection}
            setProfileData={setProfileData}
          />
          <div className="flex-1 space-y-[24px] xl:space-y-[26px]">
            <MyProfile
              profileData={profileData}
              setProfileData={setProfileData}
              saveProfileData={saveProfileData}
              updateStatus={updateStatus}
              isAnniversaryEnabled={isAnniversaryEnabled}
            />

            <GSTDetails
              additionalProfileData={additionalProfileData}
              setAdditionalProfileData={setAdditionalProfileData}
              saveAdditionalProfileData={saveAdditionalProfileData}
              additionalProfileUpdateStatus={additionalProfileUpdateStatus}
            />
            <SaveTravellers
              profileData={profileData}
              // setProfileData={setProfileData}
              // additionalProfileData={additionalProfileData}
              // setAdditionalProfileData={setAdditionalProfileData}
            />
          </div>
        </div>
      )}
      {!isAboveMediumScreen && (
        <>
          <About />
          <Footer />
        </>
      )}
    </>
  );
};

export default Profile;
