import { red_logo } from "../../../assets";

const SELLER_NUMBERS = [
  {
    number: "10+",
    text: "Years of Experience",
  },
  {
    number: "9",
    text: "Team Members",
  },
  {
    number: "14",
    text: "Country Covered",
  },
  {
    number: "2k+",
    text: "Trip Planned",
  },
];
const AboutSeller = () => {
  return (
    <div className="py-5 flex flex-col rounded-xl shadow-lg">
      <div className="bg-[#ab0101] w-full py-3 px-5 text-center text-xl rounded-t-xl text-white font-semibold">
        About Seller
      </div>
      <div className="flex md:px-10 px-10 md:gap-10 gap-5 py-5">
        <img src={red_logo} alt="logo" className="md:h-20 md:w-20 h-10 w-10" />
        <div className="flex flex-col gap-3">
          <div className="flex flex-col">
            <h1 className="text-2xl font-semibold">Adventure Travels</h1>
            <div className="inline-flex gap-5 text-[#B9B9B9]">
              <p>06 Listed Tour Packages</p>
              <span>|</span>
              <p>Since 2024</p>
            </div>
          </div>
          <p className="md:text-md text-sm">
            We believe that travel is an opportunity to experience new cultures
            and learn about the world around us. We are passionate about helping
            our clients create memories that will last a lifetime.
          </p>
          <div className="md:flex grid grid-cols-2 gap-5">
            {SELLER_NUMBERS.map((item) => (
              <div className="flex flex-col">
                <span className="text-2xl font-semibold">{item.number}</span>
                <p className="text-[#767676]">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSeller;
