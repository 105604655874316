import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import HolidayPackage from "./pages/HolidayPackage";
import Navbar from "./components/navbar/Navbar";
import SinglePackage from "./pages/SinglePackage";
import TravelGuide from "./pages/TravelGuide";
import Checkout from "./components/holiday_package/checkout/Checkout";
import Blog from "./pages/Blog";
import Offers from "./pages/Offers";
import AI from "./pages/AI";
import Help from "./pages/Help";
import BizConnect from "./pages/BizConnect";
import BizBooking from "./pages/BizBooking";
import Profile from "./pages/Profile";
import LoginSignup from "./components/login_signup/LoginSignup";
import YourBookings from "./pages/YourBookings";
import SingleBookingTicket from "./pages/SingleBookingTicket";
import SingleFAQ from "./pages/SingleFAQ";
import { useMediaQuery } from "react-responsive";
import ScrollToTop from "./components/common/ScrollToTop";
import Seller from "./pages/Seller";
import Login from "./pages/Login";
import Otp from "./pages/Otp";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import UserAgreement from "./pages/UserAgreement";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import { preloadImages } from "./utils/imagePreloader";
import { homepageimage, loginBg } from "./assets";
import Spinner from "./components/common/Spinner";
import NotFound from "./pages/NotFound";
import { useAuth } from "./contexts/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import AllHolidayPackages from "./pages/AllHolidayPackages";

const NavbarWrapper: React.FC = () => {
  const location = useLocation();
  const [loginPopup, setLoginPopup] = useState(false);
  const isAboveMediumScreen = useMediaQuery({ minWidth: 768 });
  const isSellerPage = location.pathname.startsWith("/seller/");
  const isLoginPage = location.pathname === "/login";

  if (isAboveMediumScreen && !isSellerPage && !isLoginPage) {
    return <Navbar setLoginPopup={setLoginPopup} />;
  }
  return null;
};

const App: React.FC = () => {
  const [loginPopup, setLoginPopup] = useState(false);
  const { isLoggedIn } = useAuth();
  const [areImagesPreloaded, setAreImagesPreloaded] = useState(false);

  useEffect(() => {
    const imagesToPreload = [loginBg, homepageimage];
    preloadImages(imagesToPreload).then(() => setAreImagesPreloaded(true));
  }, []);

  if (!areImagesPreloaded) {
    return <Spinner />;
  }

  return (
    <div>
      <Router>
        <ScrollToTop />
        {loginPopup && <LoginSignup setLoginPopup={setLoginPopup} />}
        <NavbarWrapper />
        <Routes>
          <Route
            path="/"
            element={
              <Home isLoggedIn={isLoggedIn} setLoginPopup={setLoginPopup} />
            }
          />
          <Route path="/holidays" element={<AllHolidayPackages />} />
          <Route path="/holiday-search" element={<HolidayPackage />} />
          <Route path="/holidays/:packageId" element={<SinglePackage />} />
          <Route
            path="/checkout/:packageId"
            element={
              <ProtectedRoute>
                <Checkout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bookings"
            element={
              <ProtectedRoute>
                <YourBookings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bookings/:ticketId"
            element={
              <ProtectedRoute>
                <SingleBookingTicket />
              </ProtectedRoute>
            }
          />
          <Route path="/travel-guide" element={<TravelGuide />} />
          <Route path="/blog/:blogId" element={<Blog />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/help" element={<Help />} />
          <Route path="/faq/:faqid" element={<SingleFAQ />} />
          <Route path="/aiplanner" element={<AI />} />
          <Route path="/biz-connect" element={<BizConnect />} />
          <Route path="/biz-booking" element={<BizBooking />} />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route path="/seller/:sellerId" element={<Seller />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify-otp" element={<Otp />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/user-agreement" element={<UserAgreement />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
