import React, { useState } from "react";
import { Check, ChevronDown, Filter as FilterIcon } from "lucide-react";
import { one_star, three_star, four_star, five_star } from "../../../assets";

interface DayOption {
  day: string;
}

interface TransportationOption {
  transportation: string;
}

interface ThemeOption {
  theme: string;
}

interface OtherOption {
  name: string;
}

type FilterOption =
  | DayOption
  | TransportationOption
  | ThemeOption
  | OtherOption;

const DAYS: DayOption[] = [
  { day: "1 to 3" },
  { day: "4 to 6" },
  { day: "7 to 9" },
  { day: "10 to 12" },
  { day: "12+" },
];

const TRANSPORTATION: TransportationOption[] = [
  { transportation: "With Flight" },
  { transportation: "Without Flight" },
  { transportation: "Private Transport" },
];

const THEME: ThemeOption[] = [
  { theme: "Solo" },
  { theme: "Religious" },
  { theme: "Adventure" },
  { theme: "Honeymoon" },
  { theme: "Group/Family" },
];

const OTHER: OtherOption[] = [
  { name: "Customizable" },
  { name: "Indian" },
  { name: "International" },
];

const getOptionLabel = (option: FilterOption): string => {
  if ("day" in option) return option.day;
  if ("transportation" in option) return option.transportation;
  if ("theme" in option) return option.theme;
  if ("name" in option) return option.name;
  return "";
};

const Filter: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<Set<string>>(
    new Set()
  );
  const [selectedRating, setSelectedRating] = useState<string | null>(null);

  const handleRatingSelect = (rating: string) => {
    setSelectedRating((prevRating) => (prevRating === rating ? null : rating));
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCheckboxChange = (label: string) => {
    setSelectedFilters((prevFilters) => {
      const newFilters = new Set(prevFilters);
      if (newFilters.has(label)) {
        newFilters.delete(label);
      } else {
        newFilters.add(label);
      }
      return newFilters;
    });
  };

  const renderCheckboxGroup = (options: FilterOption[]) => {
    return options.map((option, index) => {
      const label = getOptionLabel(option);
      return (
        <div key={index} className="inline-flex gap-2 items-center">
          <label className="custom-checkbox">
            <input
              type="checkbox"
              checked={selectedFilters.has(label)}
              onChange={() => handleCheckboxChange(label)}
            />
            <span className="checkmark"></span>
          </label>
          <p className="font-medium text-lg">{label}</p>
        </div>
      );
    });
  };

  return (
    <div
      className="mx-[20px] xl:mx-[40px] md:rounded-3xl rounded-xl mt-[30px] xl:mt-[40px] mb-[43px] border border-[#D9D9D9] bg-white z-[1000] md:sticky top-[100px] xl:top-[115px] overflow-hidden transition-all duration-300 ease-in-out"
      style={{ maxHeight: isExpanded ? "1000px" : "80px" }}
    >
      <div className="px-4 pt-4">
        <div
          className="flex items-center justify-between "
          onClick={toggleExpand}
        >
          <div className="flex items-center gap-10">
            <div className="h-[22px] cursor-pointer">
              <FilterIcon className="h-full w-full" />
            </div>
            <div className="hidden md:flex items-center gap-10">
              {[
                "Duration",
                "Hotel Rating",
                "Transportation",
                "Theme",
                "Other",
              ].map((title, index) => (
                <div
                  key={index}
                  className={`flex items-center gap-[8px] ${
                    title === "Hotel Rating" && "ml-4"
                  }  ${title === "Transportation" && "ml-6"} ${
                    title === "Theme" && "ml-4"
                  } 
                  ${title === "Other" && "ml-10"}`}
                >
                  <p className="text-[20px] font-[600]">{title}</p>
                  <ChevronDown size={16} />
                </div>
              ))}
            </div>
          </div>
          <div className="flex gap-[11px]">
            <button className="rounded-[15px] text-[16px] font-[600] flex items-center gap-3 px-[18px] py-[7px] border border-[#B9B9B9]">
              Sorted By:
              <span className="flex text-[#5E5E5E] items-center gap-[14px]">
                <span>Popularity</span>
                <ChevronDown size={16} />
              </span>
            </button>
          </div>
        </div>
      </div>

      <div
        className={`transition-all duration-300 ease-in-out ${
          isExpanded ? "opacity-100" : "opacity-0"
        }`}
      >
        <p className="px-[85px] font-medium">{`(in Days)`}</p>
        <div className="flex px-[75px] gap-10 mt-1 pb-4">
          <div className="flex flex-col gap-2 px-1">
            {renderCheckboxGroup(DAYS)}
          </div>
          <div className="grid grid-cols-2 gap-2 px-3 mb-10">
            {[
              { rating: "<3", img: one_star },
              { rating: "3", img: three_star },
              { rating: "4", img: four_star },
              { rating: "5", img: five_star },
            ].map((star, index) => (
              <div
                key={index}
                className={`h-16 w-16 rounded-xl border border-[#ADADAD] flex flex-col gap-1 items-center justify-center shadow-md font-medium text-lg cursor-pointer relative ${
                  selectedRating === star.rating
                    ? "bg-[#AB0101] text-white"
                    : ""
                }`}
                onClick={() => handleRatingSelect(star.rating)}
              >
                {selectedRating === star.rating ? (
                  <Check className="text-white" size={22} />
                ) : (
                  <>{star.rating}</>
                )}
                <img src={star.img} alt="star" />
              </div>
            ))}
          </div>
          <div className="flex flex-col gap-2 px-1">
            {renderCheckboxGroup(TRANSPORTATION)}
          </div>
          <div className="flex flex-col gap-2 px-1">
            {renderCheckboxGroup(THEME)}
          </div>
          <div className="flex flex-col gap-2 px-1">
            {renderCheckboxGroup(OTHER)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
