import { ReactNode, useState } from "react";
import { connector_line_hori, tour_image, train } from "../../../assets";

type ScheduleItem = {
  day: number;
  title: string;
  type: "train" | "bus_car" | "explore" | "multiple_explore" | "car_bus";
  des: any;
};

type PackageData = {
  schedule: ScheduleItem[];
};

type TitleProps = {
  day: number;
  title: string;
  isOpen: boolean;
  onClick: () => void;
};

type ContentWrapperProps = {
  isOpen: boolean;
  children: ReactNode;
};

type ContentComponentProps = {
  content: ScheduleItem;
  isOpen: boolean;
  onToggle: () => void;
  isLastSection?: boolean;
};

const packageData: PackageData = {
  schedule: [
    {
      day: 1,
      title: "Arrival in Delhi",
      type: "train",
      des: {
        title: "Train from Ahemadabad to Delhi",
        from: {
          time: "11:00 AM",
          date: "Fri, 01 Mar",
          location: "Ahemdabad",
        },
        to: { time: "05:30 AM", date: "Sat, 02 Mar", location: "Delhi" },
        time_diff: "18h 10m",
      },
    },
    {
      day: 2,
      title: "Delhi to Manali",
      type: "bus_car",
      des: {
        title_bus: "Volvo bus from Delhi to Manali",
        title_car: "Car transfer from Manali Volvo Stand to Manali at hotel",
        car_checkpoints: [
          "Check in at hotel",
          "Take rest or explore local market",
        ],
      },
    },
    {
      day: 3,
      title: "Rohtang Pass",
      type: "explore",
      des: {
        img: tour_image,
        title: "Full Day Rohtang pass tour by shift, Etios or similar car",
        facilites: ["3 Seater", "AC", "First Aid"],
      },
    },
    {
      day: 4,
      title: "Rohtang Pass",
      type: "explore",
      des: {
        img: tour_image,
        title: "Full Day Kullu Manikaran tour by shift, Etios or similer car",
        facilites: ["3 Seater", "AC", "First Aid"],
      },
    },
    {
      day: 5,
      title: "Sightseeing in Manali",
      type: "multiple_explore",
      des: {
        imgs: [tour_image, tour_image, tour_image, tour_image, tour_image],
        title: "Full tour by shift, Etios or similar car",
        facilites: ["3 Seater", "AC", "First Aid"],
        locations: [
          "Hadimba Temple",
          "Vashist Kund",
          "Jagatsukh",
          "Tibetian Monastery",
        ],
      },
    },
    {
      day: 6,
      title: "Checkout hotel",
      type: "car_bus",
      des: {
        title_bus: "Volvo bus from Manali to Delhi",
        title_car: "Car transfer from Manali hotel to Volvo Bus Stand",
        car_checkpoints: ["Check out at hotel"],
      },
    },
    {
      day: 7,
      title: "Delhi to Ahemdabad",
      type: "train",
      des: {
        title: "Train from Delhi to Ahemadabad",
        from: {
          time: "09:30 AM",
          date: "Fri, 04 Mar",
          location: "Delhi",
        },
        to: { time: "03:40 AM", date: "Sat, 07 Mar", location: "Ahemadabad" },
        time_diff: "18h 10m",
      },
    },
  ],
};

const Schedule: React.FC = () => {
  const [openSections, setOpenSections] = useState<boolean[]>(
    packageData.schedule.map(() => true)
  );

  const toggleSection = (index: number) => {
    setOpenSections((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };
  const Title: React.FC<TitleProps> = ({ day, title, isOpen, onClick }) => (
    <div
      className={`bg-[#191919] text-white pl-[20px] xl:pl-[30px] py-[9px] rounded-full sticky z-10 top-[60px] md:top-[153px] inline-flex gap-5 w-full items-center cursor-pointer transition-all duration-300 ease-in-out ${
        isOpen ? "" : "mb-0"
      }`}
      onClick={onClick}
    >
      <div
        className={`w-3 h-3 rounded-full transition-all duration-300 ${
          isOpen ? "bg-[#B9B9B9]" : "bg-white"
        }`}
      ></div>
      <p className="text-[16px] xl:text-[18px] font-[600] tracking-[1px]">
        Day-{day} {title}
      </p>
    </div>
  );

  const ContentWrapper: React.FC<ContentWrapperProps> = ({
    isOpen,
    children,
  }) => {
    return (
      <div
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          isOpen ? "h-auto opacity-100" : "h-0 opacity-0"
        }`}
        style={{
          height: isOpen ? "auto" : "0",
        }}
      >
        {children}
      </div>
    );
  };

  const Train: React.FC<ContentComponentProps> = ({
    content,
    isOpen,
    onToggle,
    isLastSection,
  }) => (
    <div>
      <Title
        day={content.day}
        title={content.title}
        isOpen={isOpen}
        onClick={onToggle}
      />
      <ContentWrapper isOpen={isOpen}>
        <div
          className={`py-5 px-8 md:mx-9 mx-6 flex gap-[10px] xl:gap-[15px] ${
            isLastSection ? "" : "border-l-[3px] border-dashed border-[#B9B9B9]"
          }`}
        >
          <div>
            <li className="text-[18px] xl:text-[20px] font-[400] tracking-[1px]">
              {content?.des?.title}{" "}
              <span className="font-[600]">{content?.des?.time_diff}</span>
            </li>
            <div className="mt-[8px] mx-7 flex items-center">
              <div className="min-w-fit">
                <p className="text-[18px] xl:text-[22px] font-[600] tracking-[1px]">
                  {content?.des?.from?.time}
                </p>
                <p className="text-[16px] xl:text-[20px] font-[600] text-[#5e5e5e] tracking-[1px]">
                  {content?.des?.from?.date}
                </p>
                <p className="text-[#5e5e5e] tracking-[0.8px] text-[14px] xl:text-[16px]">
                  {content?.des?.from?.location}
                </p>
              </div>
              <div className="relative flex items-center justify-center mx-[15px] xl:mx-[20px]">
                <img
                  src={train}
                  alt="train"
                  className="absolute xl:h-[24px] h-[20px] w-[15px] xl:w-[18px]"
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
                <div className="flex items-center">
                  <p className="h-1.5 w-1.5 bg-black rounded-full" />
                  <img src={connector_line_hori} alt="horizontal connector" />
                  <p className="h-1.5 w-1.5 bg-black rounded-full" />
                </div>
              </div>
              <div className="min-w-fit">
                <p className="text-[18px] xl:text-[22px] font-[600] tracking-[1px]">
                  {content?.des?.to?.time}
                </p>
                <p className="text-[16px] xl:text-[20px] font-[600] text-[#5e5e5e] tracking-[1px]">
                  {content?.des?.to?.date}
                </p>
                <p className="text-[#5e5e5e] tracking-[0.8px] text-[14px] xl:text-[16px]">
                  {content?.des?.to?.location}
                </p>
              </div>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );

  const BusToCar: React.FC<ContentComponentProps> = ({
    content,
    isOpen,
    onToggle,
  }) => (
    <div>
      <Title
        day={content.day}
        title={content.title}
        isOpen={isOpen}
        onClick={onToggle}
      />
      <ContentWrapper isOpen={isOpen}>
        <div>
          <div className="py-5 px-8 md:mx-9 mx-6 flex flex-col gap-[10px] xl:gap-[15px] border-l-[3px] border-dashed border-[#B9B9B9]">
            <li className="text-[18px] xl:text-[20px] font-[400] tracking-[1px]">
              {content?.des?.title_bus}
            </li>

            <div className="flex items-start gap-[15px] xl:gap-[28px]">
              <div className="text-[18px] xl:text-[20px] font-[400] tracking-[1px]">
                <li>{content?.des?.title_car}</li>
                <ul className="ml-5">
                  {content?.des?.car_checkpoints?.map(
                    (cdcc: string, index: number) => (
                      <li
                        className="list-disc md:ml-7 ml-5 text-sm md:text-lg"
                        key={index}
                      >
                        {cdcc}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );

  const Explore: React.FC<ContentComponentProps> = ({
    content,
    isOpen,
    onToggle,
  }) => (
    <div>
      <Title
        day={content.day}
        title={content.title}
        isOpen={isOpen}
        onClick={onToggle}
      />
      <ContentWrapper isOpen={isOpen}>
        <div className="py-5 px-8 md:mx-9 mx-6 flex gap-[10px] xl:gap-[15px] border-l-[3px] border-dashed border-[#B9B9B9]">
          <img
            src={content?.des?.img}
            alt=""
            className="rounded-[10px] h-[92px] xl:h-[100px]"
          />
          <div className="flex flex-col justify-between gap-3 xl:py-[1px] text-[15px] md:text-[18px] xl:text-[20px] leading-tight font-[400] tracking-[0.6px]">
            <p>{content?.des?.title}</p>
            <div>
              <p className="text-[#5E5E5E] leading-none">Facility</p>
              <div className="flex items-center gap-2 mt-1 md:mt-0">
                <div className="h-1.5 rounded w-1.5 bg-black" />
                <li className="flex list-disc items-center gap-1 text-xs md:text-lg">
                  {content?.des?.facilites?.map((f: string, index: number) => (
                    <div key={index} className="flex items-center">
                      <span>{f}</span>
                      <span
                        className={`${
                          index === content?.des?.facilites?.length - 1
                            ? "hidden"
                            : ""
                        } ml-1`}
                      >
                        |
                      </span>
                    </div>
                  ))}
                </li>
              </div>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );

  const MultipleExplore: React.FC<ContentComponentProps> = ({
    content,
    isOpen,
    onToggle,
  }) => (
    <div>
      <Title
        day={content.day}
        title={content.title}
        isOpen={isOpen}
        onClick={onToggle}
      />
      <ContentWrapper isOpen={isOpen}>
        <div className="py-5 px-8 md:mx-9 mx-6 flex flex-col gap-[10px] xl:gap-[15px] border-l-[3px] border-dashed border-[#B9B9B9]">
          <li className="text-[18px] xl:text-[20px] font-[400] tracking-[1px]">
            {content?.des?.title}
          </li>
          <ul className="grid grid-cols-2 mt-[5px] xl:mt-[12px] md:ml-[40px] ml-4">
            {content?.des?.locations?.map((cdl: string, index: number) => (
              <li
                className="md:text-[18px] text-[15px] xl:text-[20px] list-disc font-[400] tracking-[1px]"
                key={index}
              >
                {cdl}
              </li>
            ))}
          </ul>
          <div className="mt-[10px] xl:mt-[20px] font-[400] text-[18px] xl:text-[20px] tracking-[1px]">
            <p className="text-[#5E5E5E]">Facility</p>
            <div className="flex md:ml-[20px] items-center gap-2">
              <div className="rounded w-1.5 h-1.5 bg-black" />
              <li className="flex md:mt-1 items-center gap-1">
                {content?.des?.facilites?.map((f: string, index: number) => (
                  <div
                    className="flex items-center text-sm md:text-lg"
                    key={index}
                  >
                    <span>{f}</span>
                    <span
                      className={`${
                        index === content?.des?.facilites?.length - 1
                          ? "hidden"
                          : ""
                      } ml-1`}
                    >
                      |
                    </span>
                  </div>
                ))}
              </li>
            </div>
          </div>
          <div className="flex items-center mt-[10px] xl:mt-[15px] gap-[8px] xl:gap-[12px] mb-[10px]">
            {content?.des?.imgs?.map((cdi: string, index: number) => {
              return (
                index < 4 && (
                  <div className="relative" key={index}>
                    {index === 3 && (
                      <div className="absolute flex items-center justify-center cursor-pointer text-white text-[19px] xl:text-[23px] font-[600] tracking-[1px] top-0 left-0 right-0 bottom-0 z-10 rounded-[10px] bg-[rgba(0,0,0,0.54)]">
                        More
                      </div>
                    )}
                    <img
                      className="xl:w-[165px] w-[150px] h-[100px] xl:h-[115px] rounded-[10px]"
                      src={cdi}
                      style={{ objectFit: "cover" }}
                      alt=""
                    />
                  </div>
                )
              );
            })}
          </div>
        </div>
      </ContentWrapper>
    </div>
  );

  const CarToBus: React.FC<ContentComponentProps> = ({
    content,
    isOpen,
    onToggle,
  }) => (
    <div>
      <Title
        day={content.day}
        title={content.title}
        isOpen={isOpen}
        onClick={onToggle}
      />
      <ContentWrapper isOpen={isOpen}>
        <div>
          <div className="py-5 px-8 md:mx-9 mx-6 flex flex-col gap-10 xl:gap-[15px] border-l-[3px] border-dashed border-[#B9B9B9]">
            <div className="flex items-start gap-[15px] xl:gap-[28px]">
              <div className="text-[18px] xl:text-[20px] font-[400] tracking-[1.1px] relative">
                <li>{content?.des?.title_car}</li>
                <ul className="absolute ml-5">
                  {content?.des?.car_checkpoints?.map(
                    (cdcc: string, index: number) => (
                      <li
                        className="list-disc md:ml-7 ml-5 text-sm md:text-lg"
                        key={index}
                      >
                        {cdcc}
                      </li>
                    )
                  )}
                </ul>
              </div>
            </div>

            <div className="flex items-start mt-10">
              <li className="text-[18px] xl:text-[20px] font-[400] tracking-[1.1px]">
                {content?.des?.title_bus}
              </li>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );

  return (
    <div className="flex py-10">
      <div className="space-y-1 md:flex-1 pb-6 md:pr-5 md:mr-0">
        {packageData.schedule.map((d, index) => (
          <div key={index} data-day={index}>
            {d.type === "train" ? (
              <Train
                content={d}
                isOpen={openSections[index]}
                onToggle={() => toggleSection(index)}
                isLastSection={index === packageData.schedule.length - 1} // Check if it's the last section
              />
            ) : d.type === "bus_car" ? (
              <BusToCar
                content={d}
                isOpen={openSections[index]}
                onToggle={() => toggleSection(index)}
              />
            ) : d.type === "explore" ? (
              <Explore
                content={d}
                isOpen={openSections[index]}
                onToggle={() => toggleSection(index)}
              />
            ) : d.type === "multiple_explore" ? (
              <MultipleExplore
                content={d}
                isOpen={openSections[index]}
                onToggle={() => toggleSection(index)}
              />
            ) : d.type === "car_bus" ? (
              <CarToBus
                content={d}
                isOpen={openSections[index]}
                onToggle={() => toggleSection(index)}
              />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Schedule;
