import axios from "axios";
import { BASE_URL } from "../service.config";

export const getPublicAds = async () => {
  try {
    const url = `${BASE_URL}/api/ads/all`;
    const token = localStorage.getItem("authToken");

    if (!token) {
      throw new Error("No authentication token found");
    }

    const response = await axios.get(url, {
      headers: {
        Authorization: token,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching public ads:", error);
    throw error;
  }
};
