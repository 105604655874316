import React, { Dispatch, useEffect, useState } from "react";
import { close, google, loginBg } from "../../assets";
import { TailSpin } from "react-loader-spinner";
import { sendOTP, verifyOTP } from "../../api/auth";
import { useAuth } from "../../contexts/AuthContext";

const LoginSignup = ({
  setLoginPopup,
}: {
  setLoginPopup: Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { setIsLoggedIn } = useAuth();
  const [isVisible, setIsVisible] = useState(false);
  const [input, setInput] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [countdown, setCountdown] = useState(0);

  const isEmail = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const isPhoneNumber = (value: string) => {
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    return phoneRegex.test(value);
  };

  useEffect(() => {
    setIsVisible(true);
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const createPayload = () => {
    if (isEmail(input)) {
      return { email: input };
    } else if (isPhoneNumber(input)) {
      return { phone: input };
    } else {
      throw new Error(
        "Invalid input. Please enter a valid email or phone number."
      );
    }
  };

  const handleSendOTP = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      const payload = createPayload();
      await sendOTP(payload);
      setStep(2);
      setCountdown(30);
    } catch (error) {
      console.error("Failed to send OTP:", error);
      setError(
        error instanceof Error
          ? error.message
          : "Failed to send OTP. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOTP = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      const payload = { ...createPayload(), otp };
      const response = await verifyOTP(payload);
      console.log("OTP verified successfully:", response);
      localStorage.setItem("authToken", `Bearer ${response.token}`);
      setIsLoggedIn(true);
      setLoginPopup(false);
    } catch (error) {
      console.error("Failed to verify OTP:", error);
      setError("Invalid OTP. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOTP = async () => {
    if (countdown > 0) return;
    setError("");
    setIsLoading(true);
    try {
      const payload = createPayload();
      await sendOTP(payload);
      setCountdown(30);
    } catch (error) {
      console.error("Failed to resend OTP:", error);
      setError("Failed to resend OTP. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`fixed h-screen w-screen bg-[#000000f0] text-white z-[489645216875] flex justify-center items-center transition-opacity duration-300 ${
        isVisible ? "opacity-100" : "opacity-0"
      }`}
    >
      <div className="relative lg:scale-75 xl:scale-90 2xl:scale-100">
        <img
          src={close}
          alt="close"
          className="absolute xl:-top-7 xl:-right-7 lg:-top-5 lg:-right-5 cursor-pointer"
          onClick={() => setLoginPopup(false)}
        />
        <img src={loginBg} alt="login" className="" />
        <div className="bg-[#F5F5F5] flex flex-col justify-between bottom-[27px] rounded-[20px] px-[21px] py-[27px] text-black font-inter absolute top-[27px] right-[27px]">
          <div>
            <p className="text-[20px] font-[600] leading-[24.2px]">
              Hello <span className="text-[#AB0101]">Travellers</span>👋
            </p>
            {step === 1 ? (
              <form onSubmit={handleSendOTP}>
                <p className="mt-[22px] text-[16px] font-[500] leading-[19.36px] mb-[8px]">
                  Email or Mobile Number
                </p>
                <input
                  className="text-[13px] font-[500] leading-[15.73px] px-[13.6px] w-full py-[12px] outline-none border-[0.7px] border-black rounded-[10px]"
                  type="text"
                  placeholder="Enter Email or Mobile Number"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  required
                />
                {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                <button
                  type="submit"
                  className="text-[15px] font-[700] mt-[12px] pb-[10px] pt-[12px] leading-[18.15px] w-full bg-[#AB0101] rounded-full text-white flex items-center justify-center"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <TailSpin
                      visible={true}
                      height="24"
                      width="24"
                      color="#ffffff"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                    />
                  ) : (
                    "Continue"
                  )}
                </button>
              </form>
            ) : (
              <form onSubmit={handleVerifyOTP}>
                <p className="mt-[22px] text-[16px] font-[500] leading-[19.36px] mb-[8px]">
                  Enter OTP sent to {input}
                </p>
                <input
                  className="text-[13px] font-[500] leading-[15.73px] px-[13.6px] w-full py-[12px] outline-none border-[0.7px] border-black rounded-[10px]"
                  type="text"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
                {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
                <button
                  type="submit"
                  className="text-[15px] font-[700] mt-[12px] pb-[10px] pt-[12px] leading-[18.15px] w-full bg-[#AB0101] rounded-full text-white flex items-center justify-center"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <TailSpin
                      visible={true}
                      height="24"
                      width="24"
                      color="#ffffff"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                    />
                  ) : (
                    "Verify OTP"
                  )}
                </button>
                <button
                  type="button"
                  className={`text-[#AB0101] font-[600] mt-2 ${
                    countdown > 0 ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={handleResendOTP}
                  disabled={countdown > 0}
                >
                  {countdown > 0 ? `Resend OTP (${countdown}s)` : "Resend OTP"}
                </button>
              </form>
            )}
            <div className="flex items-center gap-[9px] mt-[27px]">
              <div className="h-[1px] bg-[#5E5E5E] flex-1"></div>
              <p className="text-[#5E5E5E] font-[500] text-[12px] leading-[14.52px]">
                or login/signup using
              </p>
              <div className="h-[1px] bg-[#5E5E5E] flex-1"></div>
            </div>
            <div className="flex justify-center">
              <img
                src={google}
                alt=""
                className="h-[40px] w-[40px] mt-[22px]"
              />
            </div>
          </div>

          <p className="font-[400] w-[360px] text-[11px] leading-[13.31px] mt-4">
            By proceeding you agree with our{" "}
            <span className="font-[700] text-[#AB0101]">Privacy Policy</span> ,{" "}
            <span className="font-[700] text-[#AB0101]">T&Cs</span> and{" "}
            <span className="font-[700] text-[#AB0101]">User Agreement</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginSignup;
