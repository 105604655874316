import React, { useState } from "react";
import {
  connMan,
  connMar,
  connRev,
  connSup,
  connVis,
  connectBanner,
  helper,
} from "../assets";
import Footer from "../components/home/footer/Footer";
import About from "../components/home/about/About";
import { useMediaQuery } from "react-responsive";
import MobileNavbar from "../components/mobile-navbar/MobileNavbar";
import BizConnectRegistrationForm from "../components/bizConnect/BizConnectRegistrationForm";

const BizConnect = () => {
  const isAboveMediumScreen = useMediaQuery({ minWidth: 768 });

  const connectFeatures = [
    {
      title: "Increase Visibility",
      desc: "Reach millions of travelers and businesses actively searching for services like yours.",
      logo: connVis,
    },
    {
      title: "Streamlined Management",
      desc: "Easily manage your listings and bookings through our intuitive platform.",
      logo: connMan,
    },
    {
      title: "Boost Revenue",
      desc: "Expand your customer base and increase bookings, leading to improved profitability.",
      logo: connRev,
    },
    {
      title: "Targeted marketing",
      desc: "Access targeted marketing tools to attract your ideal audience and drive conversions.",
      logo: connMar,
    },
    {
      title: "Dedicated Support",
      desc: "Receive personalized assistance from our team to optimize your listings and maximize your success.",
      logo: connSup,
    },
  ];

  return (
    <div>
      {isAboveMediumScreen ? (
        <div className="relative text-[#ffffff]">
          <img
            src={connectBanner}
            alt="connectbanner"
            className="object-contain w-full"
          />
          <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-between items-center pr-[80px]">
            <div className="flex flex-col items-center">
              <p className="font-[700] text-[25px] xl:text-[28px] leading-[40.22px] text-center">
                BizConnect
              </p>
              <p className="w-[60%] mt-[10px] xl:mt-[12px] text-center font-[400] text-[14px] xl:text-[16px] xl:leading-[20px]">
                Expand Your Reach and Grow Your Business By selling your Travel
                Service on PLACEAA
              </p>
            </div>
            <div className="flex items-center gap-[28px] xl:gap-[40px] leading-[20px] text-[15px] xl:text-[16px]">
              <div>
                <p className="font-[700]">Not Registered?</p>
                <button className="font-[400] rounded-[10px] mt-[10px] xl:mt-[14px] py-[10px] xl:py-[12px] px-[30px] xl:px-[40px] bg-[#AB0101] cursor-pointer">
                  Join as a Partner
                </button>
              </div>
              <div>
                <p className="font-[700]">Already Registered?</p>
                <button className="font-[400] rounded-[10px] mt-[10px] xl:mt-[14px] py-[10px] xl:py-[12px] px-[30px] xl:px-[40px] bg-[#AB0101] cursor-pointer">
                  Login to your account
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-[65vh] bg-home-page-image bg-cover bg-center px-5">
          <MobileNavbar transparentBg={true} />

          <div className="flex flex-col gap-5 md:flex-row justify-between items-center mt-10">
            <div className="flex flex-col items-center">
              <p className="font-[700] text-[25px] xl:text-[28px] leading-[40.22px] text-center text-white">
                BizConnect
              </p>
              <p className="w-[80%] mt-[10px] xl:mt-[12px] text-center font-[400] text-[14px] xl:text-[16px] xl:leading-[20px] text-white">
                Expand Your Reach and Grow Your Business By selling your Travel
                Service on PLACEAA
              </p>
            </div>
            <div className="flex flex-col md:flex-row items-center gap-[28px] xl:gap-[40px] leading-[20px] text-[15px] xl:text-[16px]">
              <div>
                <p className="font-[700] text-white text-center">
                  Not Registered?
                </p>
                <button className="font-[400] text-white rounded-[10px] mt-[10px] xl:mt-[14px] py-[10px] xl:py-[12px] px-[30px] xl:px-[40px] bg-[#AB0101] cursor-pointer">
                  Join as a Partner
                </button>
              </div>
              <div>
                <p className="font-[700] text-white text-center">
                  Already Registered?
                </p>
                <button className="font-[400] text-white rounded-[10px] mt-[10px] xl:mt-[14px] py-[10px] xl:py-[12px] px-[30px] xl:px-[40px] bg-[#AB0101] cursor-pointer">
                  Login to your account
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <p className="mt-[30px] xl:mt-[40px] text-center font-[600] text-[25px] xl:text-[30px] leading-[40px] text-black">
        Why Join <span className="text-[#AB0101]">BizConnect</span> ?
      </p>

      <div className="2xl:mx-[100px] my-[50px] xl:my-[60px] text-black flex gap-y-[50px] xl:gap-y-[60px] 2xl:gap-x-[100px] flex-wrap justify-evenly items-center">
        {connectFeatures?.map((cf) => (
          <div
            className="max-w-[300px] xl:max-w-[360px] flex flex-col items-center text-center"
            key={cf?.title}
          >
            <img
              className="xl:w-[35px] xl:h-[35px] w-[30px] h-[30px] mb-[18px] xl:mb-[20px]"
              src={cf?.logo}
              alt="cflogo"
            />
            <p className="text-[22px] xl:text-[24px] font-[600] leading-[32px]">
              {cf?.title}
            </p>
            <p className="text-[16px] xl:text-[17px] font-[400] xl:leading-[25px] mt-[4px] xl:mt-[7px]">
              {cf?.desc}
            </p>
          </div>
        ))}
      </div>

      <BizConnectRegistrationForm />

      <About />
      <Footer />
    </div>
  );
};

export default BizConnect;
