import Hero from "../components/home/hero/Hero";
import BankOffers from "../components/home/bank_offers/BankOffers";
import PopularPlaces from "../components/home/popular_places/PopularPlaces";
import HolidayPackage from "../components/home/holiday_package/HolidayPackage";
import HelpCenter from "../components/home/help_center/HelpCenter";
import About from "../components/home/about/About";
import Footer from "../components/home/footer/Footer";
import SponsoredAd from "../components/home/ads/SponsoredAd";
import NextHoliday from "../components/home/next_holiday/NextHoliday";
import "../css/home.css";
import { Dispatch, useEffect, useState } from "react";
import Spinner from "../components/common/Spinner";
import { getPublicAds } from "../api/home/ads";
import PersonalizedTravelPackageForm from "../components/home/package_form/PersonalizedTravelPackageForm";

interface Ad {
  id: number;
  banner: string;
  link: string;
  views: number;
  clickCount: number;
  addedBy: number;
  createdAt: string;
}

const Home = ({
  isLoggedIn,
  setLoginPopup,
}: {
  isLoggedIn: boolean;
  setLoginPopup: Dispatch<boolean>;
}) => {
  const [ads, setAds] = useState<Ad[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await getPublicAds();
        setAds(response);
      } catch (error) {
        console.error("Error fetching public ads:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAds();
  }, []);

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <Hero isLoggedIn={isLoggedIn} setLoginPopup={setLoginPopup} />
      <BankOffers />
      <NextHoliday />
      <div className="mt-[70px] md:mx-0 mx-3">
        {ads.length > 0 && <SponsoredAd ad={ads[0]} />}
      </div>
      {/* <BookHotel /> */}
      <div className="md:mt-[60px] mt-[40px]" />
      <PersonalizedTravelPackageForm />
      <PopularPlaces />
      <HelpCenter />
      <HolidayPackage />
      <div className="mt-[70px] md:mx-0 mx-3">
        {ads.length > 1 && <SponsoredAd ad={ads[1]} />}
      </div>
      <div className="mb-[60px]" />
      <About />
      <Footer />
    </div>
  );
};

export default Home;
