import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

interface DragAndDropFileUploadProps {
  onFileSelect: (file: File) => void;
}

const DragAndDropFileUpload: React.FC<DragAndDropFileUploadProps> = ({
  onFileSelect,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setSelectedFile(file);
        onFileSelect(file);
      }
    },
  });

  useEffect(() => {
    if (selectedFile) {
      const previewUrl = URL.createObjectURL(selectedFile);
      setPreview(previewUrl);

      // Clean up the preview URL when the component unmounts
      return () => URL.revokeObjectURL(previewUrl);
    }
  }, [selectedFile]);

  return (
    <div className="relative">
      <div
        {...getRootProps()}
        className="border-dashed border-2 border-gray-300 p-4 rounded-lg text-center cursor-pointer h-[150px] flex justify-center items-center"
      >
        <input {...getInputProps()} />
        {!preview && <p>Drag & drop a file here, or click to select a file</p>}
        {preview && (
          <img
            src={preview}
            alt="Selected file preview"
            className="absolute inset-y-5 inset-x-40 object-cover w-28 h-28 rounded-lg"
          />
        )}
      </div>
      {selectedFile && (
        <div className="mt-2 text-center">
          <p>Selected file: {selectedFile.name}</p>
        </div>
      )}
    </div>
  );
};

export default DragAndDropFileUpload;
