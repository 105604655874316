import React, { ChangeEvent, useState } from "react";
import { helper } from "../../assets";
import {
  applyBizConnect,
  verifyEmailOtp,
  verifyPhoneOtp,
} from "../../api/bizConnect";
import { TailSpin } from "react-loader-spinner";
import { uploadProfileImageWithUrl } from "../../api/profile";
import BizConnectStatusCheck from "./BizConnectStatusCheck";
import { toast } from "react-toastify";
import axios from "axios";

interface FileData {
  panCardPdf: File | null;
  registrationDocument: File | null;
}

const BizConnectRegistrationForm = () => {
  const initialData = {
    fullName: "",
    email: "",
    phone: "",
    companyPanDetails: "",
    companyName: "",
    natureOfBusiness: "",
  };

  const initialFileData = {
    panCardPdf: null,
    registrationDocument: null,
  };
  const [data, setData] = useState(initialData);
  const [fileData, setFileData] = useState<FileData>(initialFileData);
  const [emailOTP, setEmailOTP] = useState("");
  const [phoneOTP, setPhoneOTP] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [applicationId, setApplicationId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState({
    submit: false,
    emailVerify: false,
    phoneVerify: false,
  });
  const [formType, setFormType] = useState<"apply" | "status">("apply");

  const handleFormTypeChange = (type: "apply" | "status") => {
    setFormType(type);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value, type, files } = e.target as HTMLInputElement;
    if (type === "file" && files) {
      setFileData((prev) => ({
        ...prev,
        [name]: files[0],
      }));
    } else {
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const resetForm = () => {
    setData(initialData);
    setFileData(initialFileData);
    setEmailOTP("");
    setPhoneOTP("");
    setIsEmailVerified(false);
    setIsPhoneVerified(false);
    setApplicationId(null);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isPanCardValid = fileData.panCardPdf !== null;
    const isRegistrationDocValid = fileData.registrationDocument !== null;

    if (!isPanCardValid || !isRegistrationDocValid) {
      toast.error("Please select all required files before submitting.");
      return;
    }

    setIsLoading((prev) => ({ ...prev, submit: true }));
    try {
      let panCardPdfUrl = "";
      let registrationDocument = "";

      if (fileData.panCardPdf) {
        const panCardUploadResponse = await uploadProfileImageWithUrl(
          fileData.panCardPdf
        );
        panCardPdfUrl = panCardUploadResponse.url;
      }

      if (fileData.registrationDocument) {
        const registrationDocumentUploadResponse =
          await uploadProfileImageWithUrl(fileData.registrationDocument);
        registrationDocument = registrationDocumentUploadResponse.url;
      }

      const payload = {
        ...data,
        panCardPdfUrl,
        registrationDocument,
      };

      const response = await applyBizConnect(payload);
      setApplicationId(response.applicationId);
      toast.success(
        "OTPs have been sent to your email and phone. Please verify them to complete your application."
      );
    } catch (error) {
      console.error("Error submitting BizConnect application:", error);
      if (axios.isAxiosError(error) && error.response) {
        const errorMessage =
          (error.response.data as { error?: string }).error ||
          "An unknown error occurred";
        toast.error(errorMessage);
      } else {
        toast.error(
          "Failed to submit BizConnect application. Please try again."
        );
      }
    } finally {
      setIsLoading((prev) => ({ ...prev, submit: false }));
    }
  };

  const handleVerifyOTP = async (type: "email" | "phone") => {
    if (!applicationId) {
      toast.error("Please submit the form first to receive OTPs.");
      return;
    }
    setIsLoading((prev) => ({ ...prev, [type + "Verify"]: true }));

    try {
      const payload = {
        applicationId,
        code: type === "email" ? emailOTP : phoneOTP,
      };

      const response =
        type === "email"
          ? await verifyEmailOtp(payload)
          : await verifyPhoneOtp(payload);

      if (
        response.message === "Email verified successfully." ||
        response.message === "Phone number verified successfully."
      ) {
        if (type === "email") {
          setIsEmailVerified(true);
        } else {
          setIsPhoneVerified(true);
        }
        toast.success(
          `${
            type.charAt(0).toUpperCase() + type.slice(1)
          } verified successfully`
        );
        if (
          (type === "email" && isPhoneVerified) ||
          (type === "phone" && isEmailVerified)
        ) {
          toast.success("BizConnect application submitted successfully!");
          resetForm();
        }
      }
    } catch (error) {
      console.error(`Error verifying ${type} OTP:`, error);
      toast.error(`Failed to verify ${type} OTP`);
    } finally {
      setIsLoading((prev) => ({ ...prev, [type + "Verify"]: false }));
    }
  };

  const FileInput = ({
    name,
    label,
    accept,
  }: {
    name: keyof FileData;
    label: string;
    accept: string;
  }) => (
    <>
      <label
        className="w-fit text-[14px] xl:text-[18px] font-[400]"
        htmlFor={name}
      >
        {label} <span className="text-red-500">*</span>
      </label>
      <div className="relative">
        <input
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          type="file"
          name={name}
          id={name}
          onChange={handleChange}
          accept={accept}
        />
        <div className="flex items-center">
          <button
            type="button"
            className="bg-[#AB0101] text-white px-4 py-2 rounded-l-md hover:bg-blue-600 transition-colors"
          >
            Choose
          </button>
          <span className="border border-gray-300 rounded-r-md px-4 py-2 bg-white text-gray-700 w-full truncate">
            {fileData[name] ? fileData[name]?.name : "No file chosen"}
          </span>
        </div>
      </div>
    </>
  );

  return (
    <div className="md:mx-[120px] xl:mx-[150px] my-[30px] px-5 md:px-0 xl:my-[50px] 2xl:my-[70px]">
      <div className="flex justify-center mb-5">
        <div className="grid grid-cols-2 gap-4 w-full max-w-md">
          <button
            className={`py-2 px-4 rounded ${
              formType === "apply" ? "bg-[#AB0101] text-white" : "bg-gray-200"
            }`}
            onClick={() => handleFormTypeChange("apply")}
          >
            Apply for BizConnect
          </button>
          <button
            className={`py-2 px-4 rounded ${
              formType === "status" ? "bg-[#AB0101] text-white" : "bg-gray-200"
            }`}
            onClick={() => handleFormTypeChange("status")}
          >
            Check Application Status
          </button>
        </div>
      </div>
      <div className="flex justify-center">
        <div
          className="w-fit rounded-t-[30px] py-5 md:py-[8px] xl:py-[10px] px-3 text-center md:px-[70px] xl:px-[80px]"
          style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)" }}
        >
          <p className="md:text-[14px] text-[12px] xl:text-[17px] font-[600] leading-none">
            {formType === "apply"
              ? "Join BizCONNECT today and take your business to new heights with PLACEA. Let's grow together!"
              : "Check the status of your BizCONNECT application"}
          </p>
        </div>
      </div>

      <div
        className="bg-[#F5F5F5] flex flex-col-reverse px-5 md:flex-row items-center gap-[70px] p-[25px_35px_22px_50px] 2xl:mx-[150px]"
        style={{ boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.25)" }}
      >
        <div className="flex-1">
          {formType === "apply" ? (
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-[auto,1fr] items-center gap-x-[22px] xl:gap-x-[26px] gap-y-[20px]"
            >
              <label
                className="w-fit text-[14px] xl:text-[18px] font-[400]"
                htmlFor="fullName"
              >
                Full Name <span className="text-red-500">*</span>
              </label>
              <input
                className="rounded-[8px] border border-black px-1 xl:px-2 py-0.5 xl:py-1 outline-none"
                type="text"
                name="fullName"
                id="fullName"
                value={data.fullName}
                onChange={handleChange}
                required
              />

              <label
                className="w-fit text-[14px] xl:text-[18px] font-[400]"
                htmlFor="email"
              >
                Email ID <span className="text-red-500">*</span>
              </label>
              <input
                className="rounded-[8px] border border-black px-1 xl:px-2 py-0.5 xl:py-1 outline-none"
                type="email"
                name="email"
                id="email"
                value={data.email}
                onChange={handleChange}
                required
              />

              {applicationId && !isEmailVerified && (
                <>
                  <label
                    className="w-fit text-[14px] xl:text-[18px] font-[400]"
                    htmlFor="emailOTP"
                  >
                    Email OTP
                  </label>
                  <div className="flex gap-2">
                    <input
                      className="rounded-[8px] border border-black px-1 xl:px-2 py-0.5 xl:py-1 outline-none flex-grow"
                      type="text"
                      name="emailOTP"
                      id="emailOTP"
                      value={emailOTP}
                      onChange={(e) => setEmailOTP(e.target.value)}
                    />
                    {isEmailVerified ? (
                      <span className="bg-green-500 text-white px-2 py-1 rounded-md flex items-center justify-center">
                        Verified
                      </span>
                    ) : (
                      <button
                        type="button"
                        onClick={() => handleVerifyOTP("email")}
                        className="bg-[#AB0101] text-white px-2 py-1 w-full rounded-md flex justify-center items-center"
                        disabled={isLoading.emailVerify}
                      >
                        {isLoading.emailVerify ? (
                          <TailSpin
                            visible={true}
                            height="20"
                            width="20"
                            color="#FFFFFF"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                          />
                        ) : (
                          "Verify"
                        )}
                      </button>
                    )}
                  </div>
                </>
              )}

              <label
                className="w-fit text-[14px] xl:text-[18px] font-[400]"
                htmlFor="phone"
              >
                Contact Number <span className="text-red-500">*</span>
              </label>
              <input
                className="rounded-[8px] border border-black px-1 xl:px-2 py-0.5 xl:py-1 outline-none"
                type="tel"
                name="phone"
                id="phone"
                value={data.phone}
                onChange={handleChange}
                required
              />

              {applicationId && !isPhoneVerified && (
                <>
                  <label
                    className="w-fit text-[14px] xl:text-[18px] font-[400]"
                    htmlFor="phoneOTP"
                  >
                    Phone OTP
                  </label>
                  <div className="flex gap-2">
                    <input
                      className="rounded-[8px] border border-black px-1 xl:px-2 py-0.5 xl:py-1 outline-none flex-grow"
                      type="text"
                      name="phoneOTP"
                      id="phoneOTP"
                      value={phoneOTP}
                      onChange={(e) => setPhoneOTP(e.target.value)}
                    />
                    {isPhoneVerified ? (
                      <span className="bg-green-500 text-white px-2 py-1 rounded-md flex items-center justify-center">
                        Verified
                      </span>
                    ) : (
                      <button
                        type="button"
                        onClick={() => handleVerifyOTP("phone")}
                        className="bg-[#AB0101] text-white px-2 py-1 rounded-md w-full flex justify-center items-center"
                        disabled={isLoading.phoneVerify}
                      >
                        {isLoading.phoneVerify ? (
                          <TailSpin
                            visible={true}
                            height="20"
                            width="20"
                            color="#FFFFFF"
                            ariaLabel="tail-spin-loading"
                            radius="1"
                          />
                        ) : (
                          "Verify"
                        )}
                      </button>
                    )}
                  </div>
                </>
              )}

              <label
                className="w-fit text-[14px] xl:text-[18px] font-[400]"
                htmlFor="companyName"
              >
                Company Name <span className="text-red-500">*</span>
              </label>
              <input
                className="rounded-[8px] border border-black px-1 xl:px-2 py-0.5 xl:py-1 outline-none"
                type="text"
                name="companyName"
                id="companyName"
                value={data.companyName}
                onChange={handleChange}
                required
              />

              <label
                className="w-fit text-[14px] xl:text-[18px] font-[400]"
                htmlFor="companyPanDetails"
              >
                Company PAN Number <span className="text-red-500">*</span>
              </label>
              <input
                className="rounded-[8px] border border-black px-1 xl:px-2 py-0.5 xl:py-1 outline-none"
                type="text"
                name="companyPanDetails"
                id="companyPanDetails"
                value={data.companyPanDetails}
                onChange={handleChange}
                required
              />
              <label
                className="w-fit text-[14px] xl:text-[18px] font-[400]"
                htmlFor="natureOfBusiness"
              >
                Nature of Business <span className="text-red-500">*</span>
              </label>
              <select
                className="rounded-[8px] border border-black px-1 xl:px-2 py-0.5 xl:py-1 outline-none"
                name="natureOfBusiness"
                id="natureOfBusiness"
                value={data.natureOfBusiness}
                onChange={handleChange}
                required
              >
                <option value="individual">Individual</option>
                <option value="privateLimited">Private Limited</option>
                <option value="llp">Limited Liability Partnership</option>
                <option value="other">Other</option>
              </select>

              <FileInput name="panCardPdf" label="PAN Card PDF" accept=".pdf" />

              <FileInput
                name="registrationDocument"
                label="Registration Document"
                accept=".pdf,.doc,.docx"
              />

              <div className="col-span-2">
                <button
                  type="submit"
                  className="font-inter flex justify-center items-center mt-[20px] xl:mt-[31px] text-[12px] xl:text-[16px] font-[500] text-white bg-[#AB0101] py-[5px] xl:py-[8px] w-full rounded-[10px]"
                  disabled={isLoading.submit}
                >
                  {isLoading.submit ? (
                    <TailSpin
                      visible={true}
                      height="20"
                      width="20"
                      color="#FFFFFF"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                    />
                  ) : (
                    "Submit Application"
                  )}
                </button>
              </div>
            </form>
          ) : (
            <BizConnectStatusCheck />
          )}
        </div>
        <div className="flex flex-col items-center">
          <p className="text-[18px] xl:text-[22px] font-[600] mb-[20px] xl:mb-[27px] leading-[30.17px]">
            {formType === "apply"
              ? "Fill Your Details We Contact You Soon"
              : "Check Your Application Status"}
          </p>
          <img
            className="h-[240px] xl:h-[280px]"
            src={helper}
            alt="Helper illustration"
          />
        </div>
      </div>
    </div>
  );
};

export default BizConnectRegistrationForm;
