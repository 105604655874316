import { useMediaQuery } from "react-responsive";
import Footer from "../components/home/footer/Footer";
import MobileNavbar from "../components/mobile-navbar/MobileNavbar";

const termsAndConditionsContent = [
  {
    effectiveDate: "11/July/2024",
    introduction:
      'These Terms and Conditions ("Terms") govern your use of our website, app, and services ("Services"). By accessing or using our Services, you agree to be bound by these Terms. Please read them carefully.',
    sections: [
      {
        title: "Acceptance of Terms",
        content:
          "By using our Services, you agree to comply with and be legally bound by this Agreement and our Privacy Policy. If you do not agree to these terms, please do not use our Services.",
      },
      {
        title: "Eligibility",
        content:
          "You must be at least 18 years old to use our Services. By using our Services, you represent and warrant that you meet this age requirement.",
      },
      {
        title: "Account Registration",
        content:
          "To access certain features of our Services, you may need to create an account. You agree to:",
        list: [
          "Provide accurate, current, and complete information during the registration process.",
          "Update such information to keep it accurate, current, and complete.",
          "Maintain the security of your account by protecting your password and restricting access to your account.",
          "Notify PLACEAA immediately of any unauthorized use of your account.",
        ],
      },
      {
        title: "Use of Services",
        content:
          "You agree to use our Services only for lawful purposes and in accordance with these Terms. You agree not to:",
        list: [
          "Use our Services in any way that violates any applicable law or regulation.",
          "Engage in any activity that disrupts or interferes with our Services.",
          "Attempt to gain unauthorized access to our Services or the systems and networks connected to them.",
          "Use our Services to distribute spam, viruses, or other harmful or malicious content.",
        ],
      },
      {
        title: "Bookings and Payments",
        content: [
          {
            subtitle: "a. Bookings:",
            text: "When you make a booking through PLACEAA, you agree to provide accurate and complete information. All bookings are subject to availability and confirmation.",
          },
          {
            subtitle: "b. Payments:",
            text: "You agree to pay all charges associated with your booking, including taxes and fees. Payment must be made using the methods specified on our platform.",
          },
          {
            subtitle: "c. Cancellation and Refunds:",
            text: "Cancellation and refund policies vary by service provider. Please review the specific terms and conditions of your booking. PLACEAA may charge a processing fee for cancellations.",
          },
        ],
      },
      {
        title: "Intellectual Property",
        content:
          "All content and materials available on PLACEAA, including but not limited to text, graphics, logos, and software, are the property of PLACEAA or its licensors and are protected by copyright, trademark, and other intellectual property laws. You agree not to reproduce, distribute, or create derivative works from any content without our express written permission.",
      },
      {
        title: "Third-Party Links",
        content:
          "Our Services may contain links to third-party websites or services. These links are provided for your convenience only. We do not control or endorse these third-party sites and are not responsible for their content or practices. Your use of third-party websites or services is at your own risk.",
      },
      {
        title: "Disclaimer of Warranties",
        content:
          'Our Services are provided on an "as is" and "as available" basis. PLACEAA makes no representations or warranties of any kind, express or implied, regarding the operation or availability of our Services, or the information, content, materials, or products included on our Services.',
      },
      {
        title: "Limitation of Liability",
        content:
          "To the maximum extent permitted by law, PLACEAA shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of our Services. Our total liability to you for any claims arising from your use of the Services is limited to the amount you paid, if any, for accessing our Services.",
      },
      {
        title: "Indemnification",
        content:
          "You agree to indemnify, defend, and hold harmless PLACEAA, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising out of or in any way connected with your use of our Services or your violation of these Terms.",
      },
      {
        title: "Termination",
        content:
          "PLACEAA may terminate or suspend your access to our Services at any time, without notice, for conduct that PLACEAA believes violates these Terms or is harmful to other users or the interests of PLACEAA.",
      },
      {
        title: "Governing Law",
        content:
          "PLACEAA may terminate or suspend your access to our Services at any time, without notice, for conduct that PLACEAA believes violates these Terms or is harmful to other users or the interests of PLACEAA.",
      },
      {
        title: "Dispute Resolution",
        content:
          "Any disputes arising out of or relating to these Terms or our Services shall be resolved through binding arbitration in accordance with the Indian Arbitration and Conciliation Act, 1996. The arbitration shall be conducted in Surat, Gujarat, in the English language.",
      },
      {
        title: "Changes to Terms",
        content:
          "PLACEAA reserves the right to modify these Terms at any time. We will notify you of any significant changes by posting the new terms on our website and updating the effective date. Your continued use of our Services after such changes constitutes your acceptance of the new terms.",
      },
      {
        title: "Contact Us",
        content:
          "If you have any questions or concerns about this Privacy Policy, please contact us at:",
        contactInfo: [
          "PLACEAA",
          "Email: support@placeaa.com",
          "Phone: +91 90993 31142",
        ],
      },
    ],
    conclusion: "Thank you for trusting PLACEAA with your travel needs.",
  },
];

const TermsAndConditions = () => {
  const content = termsAndConditionsContent[0];
  const isAboveMediumScreen = useMediaQuery({ minWidth: 768 });

  return (
    <>
      {!isAboveMediumScreen && <MobileNavbar />}

      <div className="w-full flex justify-center px-5 md:px-10 py-8 items-center">
        <div className="md:w-[70vw]">
          <h1 className="text-3xl font-bold mb-4 text-center">
            Terms and Conditions
          </h1>
          <p className="text-sm mb-4">
            Effective Date: {content.effectiveDate}
          </p>
          <p className="text-lg font-semibold mb-2">Welcome to PLACEAA!</p>
          <p className="mb-4">{content.introduction}</p>

          {content.sections.map((section, index) => (
            <section key={index} className="mb-6">
              <h2 className="text-xl font-semibold mb-2">
                {index + 1}. {section.title}
              </h2>
              {Array.isArray(section.content) ? (
                section.content.map((item, i) => (
                  <p key={i} className="mb-2">
                    <span className="font-semibold">{item.subtitle}</span>{" "}
                    {item.text}
                  </p>
                ))
              ) : (
                <p>{section.content}</p>
              )}
              {section.list && (
                <ul className="list-disc pl-6 mt-2">
                  {section.list.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              )}
              {section.contactInfo && (
                <div>
                  {section.contactInfo.map((info, i) => (
                    <p key={i}>{info}</p>
                  ))}
                </div>
              )}
            </section>
          ))}

          <p className="text-sm italic">{content.conclusion}</p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
