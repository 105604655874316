import { ChevronRight } from "lucide-react";
import React from "react";

interface Package {
  name: string;
  duration: string;
  price: number;
  suggestFor: string;
}

const packages: Package[] = [
  {
    name: "Delightful Shimla & Manali Vacay",
    duration: "6N/7D",
    price: 10550,
    suggestFor: "Friends / Honeymoon",
  },
  {
    name: "Delightful Shimla & Manali Vacay",
    duration: "6N/7D",
    price: 10550,
    suggestFor: "Friends / Honeymoon",
  },
  {
    name: "Delightful Shimla & Manali Vacay",
    duration: "6N/7D",
    price: 10550,
    suggestFor: "Friends / Honeymoon",
  },
  {
    name: "Delightful Shimla & Manali Vacay",
    duration: "6N/7D",
    price: 10550,
    suggestFor: "Friends / Honeymoon",
  },
  {
    name: "Delightful Shimla & Manali Vacay",
    duration: "6N/7D",
    price: 10550,
    suggestFor: "Friends / Honeymoon",
  },
];

const BestsellingPackagesTable: React.FC = () => {
  return (
    <div className="w-full bg-white rounded-2xl overflow-hidden border-[1px] border-[#B9B9B9]">
      <div className="bg-[#AB0101] text-white text-xl text-center font-semibold py-3 px-4">
        Top 5 Bestselling Package
      </div>
      <div className="hidden md:block">
        <table className="w-full ">
          <thead>
            <tr className="bg-white text-lg">
              <th
                className={
                  "py-2 px-4 text-center font-semibold border-r-[1px] border-t-[1px] border-b-[1px] border-[#B9B9B9]"
                }
              >
                Package
              </th>
              <th className="py-2 px-4 text-center font-semibold border-r-[1px] border-t-[1px] border-b-[1px] border-[#B9B9B9]">
                Duration
              </th>
              <th className="py-2 px-4 text-center font-semibold border-r-[1px] border-t-[1px] border-b-[1px] border-[#B9B9B9]">
                Price
              </th>
              <th className="py-2 px-4 text-center font-semibold border-r-[1px] border-t-[1px] border-b-[1px] border-[#B9B9B9]">
                Suggest For
              </th>
              <th className="py-2 px-4 text-center font-semibold border-t-[1px] border-b-[1px] border-[#B9B9B9]">
                Details
              </th>
            </tr>
          </thead>
          <tbody>
            {packages.map((pkg, index) => (
              <>
                <tr
                  key={index}
                  className={`${
                    index === 0 ? "bg-[#EECCCC] bg-opacity-[50%]" : "bg-white"
                  }`}
                >
                  <td
                    className={`py-3 px-4 text-center md:text-lg text-sm font-medium border-r-[1px] border-t-[1px] ${
                      index !== packages.length - 1 ? "border-b-[1px]" : ""
                    } border-[#B9B9B9]`}
                  >
                    {pkg.name}
                  </td>
                  <td
                    className={`py-3 px-4 text-center text-lg font-medium border-r-[1px] border-t-[1px] ${
                      index !== packages.length - 1 ? "border-b-[1px]" : ""
                    } border-[#B9B9B9]`}
                  >
                    {pkg.duration}
                  </td>
                  <td
                    className={`py-3 px-4 text-center text-lg font-semibold text-[#AB0101] border-r-[1px] border-t-[1px] ${
                      index !== packages.length - 1 ? "border-b-[1px]" : ""
                    } border-[#B9B9B9]`}
                  >
                    ₹{pkg.price}
                  </td>
                  <td
                    className={`py-3 px-4 text-center text-lg font-medium border-r-[1px] border-t-[1px] ${
                      index !== packages.length - 1 ? "border-b-[1px]" : ""
                    } border-[#B9B9B9]`}
                  >
                    {pkg.suggestFor}
                  </td>
                  <td
                    className={`py-3 px-4 text-center text-lg font-medium border-t-[1px] ${
                      index !== packages.length - 1 ? "border-b-[1px]" : ""
                    } border-[#B9B9B9]`}
                  >
                    <button className="text-[#AB0101] hover:underline focus:outline-none text-center">
                      View Details
                    </button>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>

      <div className="md:hidden">
        {packages.map((pkg, index) => (
          <div
            key={index}
            className={`p-4 ${
              index === 0 ? "bg-[#EECCCC] bg-opacity-50" : "bg-white"
            } ${
              index !== packages.length - 1 ? "border-b border-[#B9B9B9]" : ""
            }`}
          >
            <h3 className="text-lg font-semibold mb-2">{pkg.name}</h3>
            <div className="flex justify-between items-center mb-1">
              <span className="text-sm">Duration:</span>
              <span className="text-sm font-medium">{pkg.duration}</span>
            </div>
            <div className="flex justify-between items-center mb-1">
              <span className="text-sm">Price:</span>
              <span className="text-sm font-semibold text-[#AB0101]">
                ₹{pkg.price}
              </span>
            </div>
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm">Suggest For:</span>
              <span className="text-sm font-medium">{pkg.suggestFor}</span>
            </div>
            <button className="w-full text-[#AB0101] border border-[#AB0101] rounded py-2 px-4 flex items-center justify-center hover:bg-[#AB0101] hover:text-white transition-colors">
              View Details
              <ChevronRight className="ml-2 h-4 w-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BestsellingPackagesTable;
